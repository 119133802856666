import { useState } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Plus } from 'lucide-react';
import { cn } from '@/lib/utils';
import { format, parseISO } from 'date-fns';
import { ChartSkeleton } from './chart-skeleton';
import { ChartWatermark } from './chart-watermark';
import { useCompanyData } from '@/hooks/use-company-data';
import type { DateRange } from 'react-day-picker';

const defaultCompanies = [
  { id: 'MSTR', name: 'MicroStrategy', color: '#22c55e' },
  { id: 'COIN', name: 'Coinbase', color: '#3b82f6' },
  { id: 'RIOT', name: 'Riot Platforms', color: '#ec4899' },
  { id: 'MARA', name: 'Marathon Digital', color: '#8b5cf6' },
  { id: 'SI', name: 'Silvergate', color: '#eab308' },
] as const;

interface CompanyTrackerProps {
  dateRange?: DateRange;
}

export function CompanyTracker({ dateRange }: CompanyTrackerProps) {
  const [selectedCompanies, setSelectedCompanies] = useState(['MSTR', 'COIN', 'RIOT']);
  const [newCompany, setNewCompany] = useState('');
  const endDate = dateRange?.to ? new Date(dateRange.to) : new Date();
  const startDate = dateRange?.from ? new Date(dateRange.from) : new Date(endDate);
  startDate.setMonth(startDate.getMonth() - 3);
  const days = dateRange?.from && dateRange?.to 
    ? Math.ceil((dateRange.to.getTime() - dateRange.from.getTime()) / (1000 * 60 * 60 * 24))
    : 30;
  const { data, isLoading, error } = useCompanyData(selectedCompanies, days);

  const toggleCompany = (id: string) => {
    setSelectedCompanies(prev =>
      prev.includes(id)
        ? prev.filter(c => c !== id)
        : [...prev, id]
    );
  };

  const addCompany = () => {
    if (newCompany && !selectedCompanies.includes(newCompany)) {
      setSelectedCompanies(prev => [...prev, newCompany]);
      setNewCompany('');
    }
  };

  if (isLoading) {
    return <ChartSkeleton />;
  }

  if (error) {
    return <div>Failed to load company data</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <Input
          placeholder="Add company ticker..."
          value={newCompany}
          onChange={(e) => setNewCompany(e.target.value.toUpperCase())}
          className="w-[200px]"
        />
        <Button onClick={addCompany}>
          <Plus className="mr-2 h-4 w-4" />
          Add Company
        </Button>
      </div>

      <div className="flex gap-2 flex-wrap">
        {defaultCompanies.map(company => (
          <Button
            key={company.id}
            variant={selectedCompanies.includes(company.id) ? "default" : "outline"}
            onClick={() => toggleCompany(company.id)}
            className={cn(
              "transition-all duration-200",
              selectedCompanies.includes(company.id) && "border-2"
            )}
            style={{
              borderColor: selectedCompanies.includes(company.id) ? company.color : undefined,
              backgroundColor: selectedCompanies.includes(company.id) ? `${company.color}20` : undefined,
              color: selectedCompanies.includes(company.id) ? company.color : undefined
            }}
          >
            <span
              className="mr-2 h-3 w-3 rounded-full"
              style={{ backgroundColor: company.color }}
            />
            {company.name}
          </Button>
        ))}
      </div>

      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <ChartWatermark />
            <XAxis
              dataKey="date"
              tickFormatter={(value) => format(parseISO(value), 'MMM d')}
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `$${value.toFixed(2)}`}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (!active || !payload?.length) return null;

                return (
                  <Card className={cn(
                    "p-2 shadow-lg border-primary/20",
                    "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
                  )}>
                    <div className="text-sm font-medium">
                      {format(parseISO(label), 'MMMM d, yyyy')}
                    </div>
                    <div className="mt-1 space-y-1">
                      {payload.map((entry: any) => {
                        const company = defaultCompanies.find(c => c.id === entry.dataKey);
                        return (
                          <div key={entry.dataKey} className="flex items-center gap-2">
                            <div 
                              className="w-2 h-2 rounded-full"
                              style={{ backgroundColor: company?.color || '#888888' }}
                            />
                            <span className="text-sm">
                              {company?.name || entry.dataKey}: ${entry.value?.toFixed(2)}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                );
              }}
            />
            <Legend />
            {selectedCompanies.map(id => {
              const company = defaultCompanies.find(c => c.id === id);
              return (
                <Line
                  key={id}
                  type="monotone"
                  dataKey={id}
                  name={company?.name || id}
                  stroke={company?.color || '#888888'}
                  strokeWidth={2}
                  dot={false}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}