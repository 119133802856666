import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data stays fresh for 5 minutes
      gcTime: 1000 * 60 * 30,   // Cache persists for 30 minutes
      refetchOnWindowFocus: false, // Prevent unnecessary refetches
      retry: 2, // Reduce retry attempts
      retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
      networkMode: 'offlineFirst', // Prefer cached data when offline
      refetchOnMount: 'always', // Enable refetch on component mount
      placeholderData: 'keepPrevious', // Replaces keepPreviousData
    },
    mutations: {
      networkMode: 'offlineFirst',
      retry: 1,
    },
  }
});