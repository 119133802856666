import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { ChannelVolume } from '../components/channel-volume';
import { TopicTrends } from '../components/topic-trends';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DateRange } from 'react-day-picker';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader2, AlertCircle } from 'lucide-react';
import { startOfDay, endOfDay, subDays } from 'date-fns';
import { Alert, AlertDescription } from '@/components/ui/alert';

interface TrendsProps {
  dateRange?: DateRange;
}

// Add a loading component
function ChartLoader() {
  return (
    <div className="flex h-[400px] w-full items-center justify-center">
      <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
    </div>
  );
}

// Add an error fallback
function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  return (
    <div className="flex h-[400px] w-full flex-col items-center justify-center gap-4">
      <p className="text-destructive">Error loading chart: {error.message}</p>
      <button 
        onClick={resetErrorBoundary}
        className="rounded bg-primary px-4 py-2 text-primary-foreground"
      >
        Retry
      </button>
    </div>
  );
}

export function Trends({ dateRange }: TrendsProps) {
  // Set up default date range
  const today = startOfDay(new Date());
  const defaultDateRange: DateRange = {
    from: startOfDay(subDays(today, 30)),
    to: endOfDay(subDays(today, 1)),
  };

  const effectiveDateRange = dateRange || defaultDateRange;

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-3xl font-bold tracking-tight">Trend Analysis</h2>
          <p className="text-sm text-muted-foreground">
            Track volume distribution and topic analysis across channels
          </p>
        </div>
      </div>

      <Tabs defaultValue="topics" className="space-y-4">
        <TabsList>
          <TabsTrigger value="topics">Topic Intelligence</TabsTrigger>
          <TabsTrigger value="overview">Channel Distribution</TabsTrigger>
        </TabsList>

        <TabsContent value="overview">
          <Card>
            <CardHeader>
              <CardTitle>Channel Volume Distribution</CardTitle>
              <CardDescription>
                Volume distribution across different channels with period-over-period changes
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ChartLoader />}>
                  <ChannelVolume dateRange={effectiveDateRange} />
                </Suspense>
              </ErrorBoundary>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="topics">
          <Card>
            <CardContent>
              <ErrorBoundary
                FallbackComponent={({ error, resetErrorBoundary }) => (
                  <div className="flex h-[400px] w-full flex-col items-center justify-center gap-4">
                    <Alert variant="destructive">
                      <AlertCircle className="h-4 w-4" />
                      <AlertDescription>
                        Error loading topic trends: {error.message}
                      </AlertDescription>
                    </Alert>
                    <button 
                      onClick={resetErrorBoundary}
                      className="rounded bg-primary px-4 py-2 text-primary-foreground"
                    >
                      Retry
                    </button>
                  </div>
                )}
              >
                <Suspense fallback={<ChartLoader />}>
                  <TopicTrends dateRange={effectiveDateRange} />
                </Suspense>
              </ErrorBoundary>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}