import { useQuery } from '@tanstack/react-query';
import { ResponsiveBar } from '@nivo/bar';
import { DateRange } from 'react-day-picker';
import { format } from 'date-fns';
import { Skeleton } from '@/components/ui/skeleton';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface SentimentTimelineProps {
  dateRange?: DateRange;
  onTopicChange?: (topic: string) => void;
  selectedTopic?: string;
  availableTopics?: string[];
  showTopicSelector?: boolean;
}

interface SentimentData {
  date: string;
  positive_percentage: number;
  neutral_percentage: number;
  negative_percentage: number;
}

export function SentimentTimeline({ 
  dateRange, 
  onTopicChange,
  selectedTopic,
  availableTopics = [],
  showTopicSelector = false
}: SentimentTimelineProps) {
  const { data, isLoading, error } = useQuery<SentimentData[]>({
    queryKey: ['sentiment-timeline', dateRange?.from, dateRange?.to, selectedTopic],
    queryFn: async () => {
      if (!dateRange?.from || !dateRange?.to) return null;
      
      const startDate = format(dateRange.from, 'yyyy-MM-dd');
      const endDate = format(dateRange.to, 'yyyy-MM-dd');
      
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/daily-sentiment-percentages?startDate=${startDate}&endDate=${endDate}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch sentiment data');
      }
      
      const dailyData = await response.json();

      if (selectedTopic && selectedTopic !== 'all') {
        const topicResponse = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/topic-sentiment?startDate=${startDate}&endDate=${endDate}`
        );
        
        if (!topicResponse.ok) {
          throw new Error('Failed to fetch topic data');
        }
        
        const topicData = await topicResponse.json();
        const filteredTopic = topicData.find((item: any) => item.topic === selectedTopic);
        
        if (!filteredTopic) return dailyData;

        const topicRatio = {
          positive: filteredTopic.positive_percentage / 100,
          neutral: filteredTopic.neutral_percentage / 100,
          negative: filteredTopic.negative_percentage / 100,
        };

        return dailyData.map((day: any) => ({
          date: day.date,
          positive_percentage: Math.round(day.positive_percentage * topicRatio.positive),
          neutral_percentage: Math.round(day.neutral_percentage * topicRatio.neutral),
          negative_percentage: Math.round(day.negative_percentage * topicRatio.negative),
        }));
      }
      
      return dailyData;
    },
    enabled: !!dateRange?.from && !!dateRange?.to,
  });

  if (isLoading) {
    return <Skeleton className="h-[400px]" />;
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>
          {error instanceof Error ? error.message : 'Failed to load sentiment data'}
        </AlertDescription>
      </Alert>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Alert>
        <AlertDescription>
          No sentiment data available for the selected date range.
        </AlertDescription>
      </Alert>
    );
  }

  const chartData = data
    ?.filter(d => d.date)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(d => {
      const total = d.positive_percentage + d.neutral_percentage + d.negative_percentage;
      const scaleFactor = total > 0 ? 100 / total : 1;

      return {
        date: format(new Date(d.date), 'MMM dd'),
        Positive: Math.round(d.positive_percentage * scaleFactor),
        Neutral: Math.round(d.neutral_percentage * scaleFactor),
        Negative: Math.round(d.negative_percentage * scaleFactor),
      };
    }) || [];

  console.log('Final chart data:', chartData);

  return (
    <div className="space-y-4">
      {showTopicSelector && (
        <div className="flex items-center gap-2">
          <Select
            value={selectedTopic}
            onValueChange={onTopicChange}
          >
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder="Select topic">
                {selectedTopic || "Select topic"}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Topics</SelectItem>
              {availableTopics?.map(topic => (
                <SelectItem key={topic} value={topic}>
                  {topic}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      <div className={showTopicSelector ? "h-[400px]" : "h-full"}>
        <ResponsiveBar
          data={chartData}
          keys={['Positive', 'Neutral', 'Negative']}
          indexBy="date"
          margin={{ top: 30, right: 130, bottom: 70, left: 60 }}
          padding={0.2}
          valueScale={{ type: 'linear', min: 0, max: 100 }}
          indexScale={{ type: 'band', round: true }}
          colors={[
            '#22c55e', // Positive - green
            'hsl(var(--muted-foreground) / 0.3)', // Neutral - semi-transparent gray
            '#ef4444' // Negative - red
          ]}
          groupMode="stacked"
          enableTotals={false}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: 'hsl(var(--border))',
                  strokeWidth: 1,
                },
              },
              ticks: {
                line: {
                  stroke: 'hsl(var(--border))',
                  strokeWidth: 1,
                },
                text: {
                  fill: 'hsl(var(--muted-foreground))',
                  fontSize: 11,
                },
              },
              legend: {
                text: {
                  fill: 'hsl(var(--muted-foreground))',
                  fontSize: 12,
                  fontWeight: 500,
                },
              },
            },
            grid: {
              line: {
                stroke: 'hsl(var(--border))',
                strokeWidth: 1,
              },
            },
            legends: {
              text: {
                fill: 'hsl(var(--foreground))',
                fontSize: 12,
                fontWeight: 500,
              },
            },
            tooltip: {
              container: {
                background: 'hsl(var(--background))',
                color: 'hsl(var(--foreground))',
                fontSize: '12px',
                borderRadius: '6px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              },
            },
          }}
          enableLabel={false}
          borderRadius={1}
          borderWidth={0}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.6]]
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Date',
            legendPosition: 'middle',
            legendOffset: 60,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Sentiment Distribution %',
            legendPosition: 'middle',
            legendOffset: -40,
            format: (value) => `${value}%`
          }}
          enableGridY={true}
          enableGridX={false}
          gridYValues={5}
          labelSkipWidth={12}
          labelSkipHeight={12}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 4,
              itemWidth: 100,
              itemHeight: 18,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 10,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          animate={true}
          motionConfig="gentle"
          role="application"
          ariaLabel="Sentiment Timeline"
          barAriaLabel={e => `${e.id}: ${e.formattedValue}% on ${e.indexValue}`}
          tooltip={({ id, value, color }) => (
            <div
              style={{
                padding: 12,
                background: 'hsl(var(--background))',
                color: 'hsl(var(--foreground))',
                borderRadius: '6px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <div style={{ color: color }}>{id}</div>
              <strong>{value}%</strong>
            </div>
          )}
        />
      </div>
    </div>
  );
}