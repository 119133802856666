import { format, addDays, endOfDay } from 'date-fns';
import { apiRequest } from './base';

export interface FeedEntry {
  Title: string;
  Content: string;
  Date: string;
  URL: string;
  Outlet: string;
  From?: string;
  Outlet_Category?: string;
  Sentiment: 'Positive' | 'Neutral' | 'Negative';
}

interface FeedParams {
  startDate?: string;
  endDate?: string;
  outlet?: string;
  sentiment?: string;
  limit?: number;
}

export async function fetchFeed({ 
  startDate, 
  endDate, 
  outlet, 
  sentiment,
  limit 
}: FeedParams = {}): Promise<FeedEntry[]> {
  try {
    const adjustedEndDate = endDate ? format(addDays(endOfDay(new Date(endDate)), 1), 'yyyy-MM-dd') : undefined;
    
    const response = await apiRequest<FeedEntry[]>('/feed', {
      startDate,
      endDate: adjustedEndDate,
      outlet,
      sentiment,
      limit: limit?.toString(),
      userId: 'perception'
    });

    if (!Array.isArray(response)) {
      console.error('[Feed API] Invalid response format:', response);
      return [];
    }

    // Process and normalize the data without slicing
    return response
      .map(entry => ({
        ...entry,
        Date: entry.Date.includes('T') ? entry.Date : `${entry.Date}T00:00:00Z`,
        Content: entry.Content || '',
        Title: entry.Title || '',
        Sentiment: entry.Sentiment || 'Neutral',
        Outlet: entry.Outlet || 'Unknown',
        Outlet_Category: entry.Outlet_Category || 'Other',
        URL: entry.URL || '#'
      }))
      .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());

  } catch (error) {
    console.error('[Feed API] Error:', error);
    return [];
  }
}