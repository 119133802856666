import { memo } from 'react';

interface ChartWatermarkProps {
  opacity?: number;
}

export const ChartWatermark = memo(function ChartWatermark({ 
  opacity = 0.08  // Slightly increased opacity for better visibility
}: ChartWatermarkProps) {
  return (
    <>
      <defs>
        <pattern 
          id="watermarkPattern" 
          patternUnits="userSpaceOnUse"
          width="100%" 
          height="100%"
        >
          <text
            x="50%"
            y="50%"
            fontSize="16"
            fill="currentColor"
            opacity={opacity}
            textAnchor="middle"
            dominantBaseline="middle"
            fontFamily="system-ui, -apple-system, sans-serif"
            transform="rotate(-45, 50%, 50%)"
          >
            bitcoin perception
          </text>
        </pattern>
      </defs>
      <rect 
        width="100%" 
        height="100%" 
        fill="url(#watermarkPattern)"
        style={{
          pointerEvents: 'none',
          mixBlendMode: 'multiply'
        }}
      />
    </>
  );
});