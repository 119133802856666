import { useChartData } from '@/hooks/use-chart-data';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { ChartLoader } from '@/components/ui/chart-loader';
import { format } from "date-fns";
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from "recharts";

interface BitcoinProxiesProps {
  dateRange?: DateRange;
}

interface FearGreedData {
  date: string;
  value: number;
  classification: string;
}

export function BitcoinProxies({ dateRange }: BitcoinProxiesProps) {
  const { data, isLoading, error } = useChartData('fear-greed-index', dateRange);

  const chartData = useMemo(() => {
    if (!data) return [];
    return data.map((item: FearGreedData) => ({
      date: new Date(item.date),
      value: item.value,
      classification: item.classification,
    }));
  }, [data]);

  if (isLoading) return <ChartLoader />;
  if (error) throw error;

  const currentValue = chartData[chartData.length - 1]?.value || 0;
  const currentClassification = chartData[chartData.length - 1]?.classification || 'Neutral';

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-2xl font-bold">{currentValue}</h3>
          <p className="text-sm text-muted-foreground">{currentClassification}</p>
        </div>
      </div>

      <div className="h-[200px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              tickFormatter={(date) => format(date, 'MMM dd')}
              scale="time"
              type="number"
              domain={['auto', 'auto']}
            />
            <YAxis domain={[0, 100]} />
            <Tooltip
              content={({ active, payload }) => {
                if (!active || !payload?.length) return null;
                const data = payload[0].payload;
                return (
                  <div className="bg-background border rounded-lg p-2 shadow-lg">
                    <p className="text-sm font-medium">
                      {format(data.date, 'MMM dd, yyyy')}
                    </p>
                    <p className="text-sm">Value: {data.value}</p>
                    <p className="text-sm">
                      Classification: {data.classification}
                    </p>
                  </div>
                );
              }}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorValue)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}