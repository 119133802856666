import { createContext, useContext, ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format, isValid, parseISO, subDays } from 'date-fns';
import { fetchChannelVolumeData, type ChannelVolumeResponse } from '@/lib/api/channel-volume';
import type { DateRange } from 'react-day-picker';

export const CHANNEL_COLORS = {
  'Bitcoin Repositories': 'bg-amber-500',
  'Communities': 'bg-blue-500',
  'Crypto Media': 'bg-purple-500',
  'Financial Media': 'bg-green-500',
  'Legacy Media': 'bg-red-500',
  'Social Media': 'bg-indigo-500',
} as const;

interface ProcessedChannelData {
  name: string;
  volume: number;
  change: number;
  color: string;
}

interface ChannelVolumeContextType {
  data: ProcessedChannelData[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => void;
  dateRange?: DateRange;
}

const ChannelVolumeContext = createContext<ChannelVolumeContextType | undefined>(undefined);

interface ChannelVolumeProviderProps {
  children: ReactNode;
  dateRange?: DateRange;
}

function processChannelData(
  currentPeriodData: ChannelVolumeResponse[] | undefined,
  previousPeriodData: ChannelVolumeResponse[] | undefined
): ProcessedChannelData[] {
  if (!currentPeriodData || !previousPeriodData) {
    console.warn('Missing data for channel volume processing');
    return [];
  }

  if (!Array.isArray(currentPeriodData) || !Array.isArray(previousPeriodData)) {
    console.error('Invalid data format received');
    return [];
  }

  return Object.entries(CHANNEL_COLORS).map(([channel, color]) => {
    const currentData = currentPeriodData.find(d => d.channel_category === channel);
    const previousData = previousPeriodData.find(d => d.channel_category === channel);
    
    const volume = currentData?.percentage || 0;
    const previousVolume = previousData?.percentage || 0;
    const change = Number((volume - previousVolume).toFixed(1));

    return {
      name: channel,
      volume: Number(volume.toFixed(1)),
      change,
      color,
    };
  }).sort((a, b) => b.volume - a.volume);
}

export function ChannelVolumeProvider({ children, dateRange }: ChannelVolumeProviderProps) {
  // Default to last 7 days if no date range is provided
  const now = new Date();
  const defaultEndDate = format(now, 'yyyy-MM-dd');
  const defaultStartDate = format(subDays(now, 6), 'yyyy-MM-dd');

  // Use provided date range if valid, otherwise use defaults
  const endDate = dateRange?.to && isValid(dateRange.to) 
    ? format(dateRange.to, 'yyyy-MM-dd') 
    : defaultEndDate;

  const startDate = dateRange?.from && isValid(dateRange.from)
    ? format(dateRange.from, 'yyyy-MM-dd')
    : defaultStartDate;

  // Calculate duration for previous period
  const duration = dateRange?.from && dateRange?.to && isValid(dateRange.from) && isValid(dateRange.to)
    ? dateRange.to.getTime() - dateRange.from.getTime()
    : 7 * 24 * 60 * 60 * 1000;

  // Calculate previous period dates
  const previousEndDate = format(parseISO(startDate), 'yyyy-MM-dd');
  const previousStartDate = format(new Date(parseISO(previousEndDate).getTime() - duration), 'yyyy-MM-dd');

  const currentPeriodQuery = useQuery({
    queryKey: ['channel-volume', startDate, endDate],
    queryFn: () => fetchChannelVolumeData(startDate, endDate),
    staleTime: 5 * 60 * 1000,
    retry: 2,
    initialData: [],
  });

  if (currentPeriodQuery.error) {
    console.error('Error fetching channel volume data:', currentPeriodQuery.error);
  }

  const previousPeriodQuery = useQuery({
    queryKey: ['channel-volume', previousStartDate, previousEndDate],
    queryFn: () => fetchChannelVolumeData(previousStartDate, previousEndDate),
    staleTime: 5 * 60 * 1000,
    retry: 3,
    initialData: [],
  });

  const isLoading = currentPeriodQuery.isLoading || previousPeriodQuery.isLoading;
  const error = (currentPeriodQuery.error || previousPeriodQuery.error) as Error | null;

  const data = (() => {
    try {
      if (!currentPeriodQuery.data || !previousPeriodQuery.data) {
        console.debug('[ChannelVolume] Missing query data', {
          current: currentPeriodQuery.data,
          previous: previousPeriodQuery.data
        });
        return [];
      }

      if (!Array.isArray(currentPeriodQuery.data) || !Array.isArray(previousPeriodQuery.data)) {
        console.error('[ChannelVolume] Invalid data format', {
          current: currentPeriodQuery.data,
          previous: previousPeriodQuery.data
        });
        return [];
      }

      return processChannelData(currentPeriodQuery.data, previousPeriodQuery.data);
    } catch (error) {
      console.error('[ChannelVolume] Error processing data:', error);
      return [];
    }
  })();

  const value = {
    data,
    isLoading,
    error,
    refetch: () => {
      currentPeriodQuery.refetch();
      previousPeriodQuery.refetch();
    },
    dateRange,
  };

  return (
    <ChannelVolumeContext.Provider value={value}>
      {children}
    </ChannelVolumeContext.Provider>
  );
}

export function useChannelVolume() {
  const context = useContext(ChannelVolumeContext);
  if (context === undefined) {
    throw new Error('useChannelVolume must be used within a ChannelVolumeProvider');
  }
  return context;
}