import { useQuery } from '@tanstack/react-query';
import { format, subHours } from 'date-fns';

interface FeedItem {
  Content: string;
  Title: string;
  Sentiment: 'Positive' | 'Neutral' | 'Negative';
  Outlet: string;
  Date: string;
  URL: string;
}

interface ChartData {
  name: string;
  value: number;
  count: number;
  color: string;
}

export function useDailySentiment() {
  return useQuery<FeedItem[], Error, ChartData[]>({
    queryKey: ['daily-sentiment'],
    queryFn: async () => {
      const endDate = new Date();
      const startDate = subHours(endDate, 24);
      
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/feed?startDate=${format(startDate, 'yyyy-MM-dd')}&endDate=${format(endDate, 'yyyy-MM-dd')}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch feed data');
      }
      
      const data = await response.json();
      console.log('Sample feed item:', data[0]);
      return data;
    },
    select: (data) => {
      if (!data || data.length === 0) {
        return [];
      }

      const cutoffTimestamp = subHours(new Date(), 24).getTime();
      console.log('Cutoff timestamp:', cutoffTimestamp);

      const counts = data.reduce((acc, item) => {
        try {
          const [datePart, timePart] = item.Date.split(' ');
          const [year, month, day] = datePart.split('-').map(Number);
          const [hours, minutes, seconds] = timePart.split(':').map(Number);
          
          const itemDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
          const itemTimestamp = itemDate.getTime();

          console.log(`Processing item:`, {
            original: item.Date,
            parsed: itemDate,
            timestamp: itemTimestamp
          });
          
          if (itemTimestamp >= cutoffTimestamp) {
            acc[item.Sentiment] = (acc[item.Sentiment] || 0) + 1;
          }
        } catch (error) {
          console.error('Error parsing date:', item.Date, error);
        }
        return acc;
      }, {
        Positive: 0,
        Neutral: 0,
        Negative: 0
      } as Record<string, number>);

      const total = Object.values(counts).reduce((sum, count) => sum + count, 0);
      const getPercentage = (count: number) => (total > 0 ? Math.round((count / total) * 100) : 0);

      console.log('24h sentiment counts:', counts);
      console.log('Total mentions:', total);

      return [
        {
          name: 'Positive',
          value: getPercentage(counts.Positive),
          count: counts.Positive,
          color: '#22c55e'
        },
        {
          name: 'Neutral',
          value: getPercentage(counts.Neutral),
          count: counts.Neutral,
          color: 'hsl(var(--muted-foreground) / 0.3)'
        },
        {
          name: 'Negative',
          value: getPercentage(counts.Negative),
          count: counts.Negative,
          color: '#ef4444'
        }
      ];
    },
    refetchInterval: 5 * 60 * 1000,
  });
} 