import { useState, useRef, useEffect } from 'react';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { format, parseISO, subMonths } from 'date-fns';
import { ChartWatermark } from './chart-watermark';
import { useQuery } from '@tanstack/react-query';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';
import type { DateRange } from 'react-day-picker';

const segments = [
  { id: 'lightning', name: 'Lightning Network', color: '#f7931a' },
  { id: 'l2', name: 'Layer 2 Solutions', color: '#3b82f6' },
  { id: 'mining', name: 'Mining', color: '#22c55e' },
  { id: 'hardware', name: 'Hardware', color: '#ec4899' },
  { id: 'infrastructure', name: 'Infrastructure', color: '#8b5cf6' },
] as const;

interface TopicSentiment {
  topic: string;
  positive: number;
  neutral: number;
  negative: number;
  total: number;
}

async function fetchTopicSentiment(endDate: string): Promise<TopicSentiment[]> {
  try {
    const adjustedStartDate = format(subMonths(new Date(endDate), 3), 'yyyy-MM-dd');
    
    const response = await fetch(
      `https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/topic-sentiment?startDate=${adjustedStartDate}&endDate=${endDate}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    // Ensure data is an array and has the correct shape
    if (!Array.isArray(data)) {
      console.error('Expected array but got:', typeof data);
      return [];
    }
    
    // Validate and transform the data
    return data.filter((item): item is TopicSentiment => {
      return item && 
        typeof item.topic === 'string' &&
        typeof item.positive === 'number' &&
        typeof item.neutral === 'number' &&
        typeof item.negative === 'number' &&
        typeof item.total === 'number';
    });
  } catch (error) {
    console.error('Error fetching topic sentiment:', error);
    return [];
  }
}

interface ChartDataPoint {
  date: string;
  [key: string]: string | number;  // Allow dynamic segment keys
}

interface IndustrySegmentsProps {
  dateRange?: DateRange;
}

export function IndustrySegments({ dateRange }: IndustrySegmentsProps) {
  const [selectedSegments, setSelectedSegments] = useState(['lightning', 'l2', 'mining']);
  const chartRef = useRef<HTMLDivElement>(null);
  
  // Use the end date from the date range
  const endDate = format(dateRange?.to ?? new Date(), 'yyyy-MM-dd');

  const { data: topicSentimentData, isLoading, isError } = useQuery({
    queryKey: ['topicSentiment', endDate],
    queryFn: () => fetchTopicSentiment(endDate),
    retry: 1,
  });

  const toggleSegment = (id: string) => {
    setSelectedSegments(prev =>
      prev.includes(id)
        ? prev.filter(s => s !== id)
        : [...prev, id]
    );
  };

  // Make the ref available globally for the capture function
  useEffect(() => {
    if (chartRef.current) {
      (window as any).__industrySegmentsChart = chartRef.current;
    }
    return () => {
      delete (window as any).__industrySegmentsChart;
    };
  }, []);

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Industry Segments</CardTitle>
        </CardHeader>
        <CardContent>
          <Skeleton className="h-[200px] w-full" />
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Industry Segments</CardTitle>
        </CardHeader>
        <CardContent>
          <Alert variant="destructive">
            <AlertDescription>
              Unable to load industry segments data. Please try again later.
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  if (!topicSentimentData || topicSentimentData.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Industry Segments</CardTitle>
        </CardHeader>
        <CardContent>
          <Alert>
            <AlertDescription>
              No industry segments data available for the selected period.
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  // Generate mock data with both percentage and volume metrics
  const data = Array.from({ length: 30 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - (29 - i));
    
    const baseData = {
      date: format(date, 'yyyy-MM-dd')
    } as ChartDataPoint;

    // Add segment percentages and volumes
    segments.forEach(segment => {
      // Base trend values that stay somewhat consistent
      let baseTrend = {
        'lightning': 65,
        'l2': 55,
        'mining': 45,
        'hardware': 35,
        'infrastructure': 30,
      }[segment.id] || 40;

      // Add some random variation with momentum
      const momentum = Math.sin(date.getTime() / (1000 * 60 * 60 * 24 * 7)) * 5;
      const noise = (Math.random() - 0.5) * 10;
      
      // Calculate percentage (0-100)
      const percentage = Math.max(0, Math.min(100, baseTrend + momentum + noise));
      
      // Calculate volume (larger numbers)
      const volume = Math.round(percentage * 100 + Math.random() * 1000);

      baseData[`${segment.id}_pct`] = percentage;
      baseData[`${segment.id}_vol`] = volume;
    });

    return baseData;
  });

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 flex-wrap">
          {segments.map(segment => (
            <Button
              key={segment.id}
              variant={selectedSegments.includes(segment.id) ? "default" : "outline"}
              onClick={() => toggleSegment(segment.id)}
              className={cn(
                "transition-all duration-200",
                selectedSegments.includes(segment.id) && "border-2"
              )}
              style={{
                borderColor: selectedSegments.includes(segment.id) ? segment.color : undefined,
                backgroundColor: selectedSegments.includes(segment.id) ? `${segment.color}20` : undefined,
                color: selectedSegments.includes(segment.id) ? segment.color : undefined
              }}
            >
              <span
                className="mr-2 h-3 w-3 rounded-full"
                style={{ backgroundColor: segment.color }}
              />
              {segment.name}
            </Button>
          ))}
        </div>
        <Select>
          <SelectTrigger className="w-[200px]">
            <SelectValue placeholder="Select a topic" />
          </SelectTrigger>
          <SelectContent>
            {Array.isArray(topicSentimentData) ? topicSentimentData.map((item) => (
              <SelectItem key={item.topic} value={item.topic}>
                {item.topic}
              </SelectItem>
            )) : null}
          </SelectContent>
        </Select>
      </div>

      <div 
        ref={chartRef}
        className="h-[400px] chart-container"
        data-testid="industry-segments-chart"
        data-chart-type="industry-segments"
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart 
            data={data} 
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            className="industry-segments-chart"
          >
            <ChartWatermark />
            <XAxis
              dataKey="date"
              tickFormatter={(value) => format(parseISO(value), 'MMM d')}
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              yAxisId="percentage"
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${value}%`}
              domain={[0, 100]}
            />
            <YAxis
              yAxisId="volume"
              orientation="right"
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${value}`}
              label={{ 
                value: 'Volume',
                angle: 90,
                position: 'right',
                offset: 20,
                style: {
                  textAnchor: 'middle',
                  fill: '#888888',
                  fontSize: 12
                }
              }}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (!active || !payload?.length) return null;

                return (
                  <Card className={cn(
                    "p-2 shadow-lg border-primary/20",
                    "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
                  )}>
                    <div className="text-sm font-medium">
                      {format(parseISO(label), 'MMMM d, yyyy')}
                    </div>
                    <div className="mt-1 space-y-1">
                      {selectedSegments.map(segmentId => {
                        const segment = segments.find(s => s.id === segmentId);
                        if (!segment) return null;

                        const pctEntry = payload.find(p => p.dataKey === `${segmentId}_pct`);
                        const volEntry = payload.find(p => p.dataKey === `${segmentId}_vol`);

                        return (
                          <div key={segmentId} className="space-y-1">
                            <div className="font-medium" style={{ color: segment.color }}>
                              {segment.name}
                            </div>
                            <div className="ml-2 space-y-0.5 text-sm">
                              <div className="flex justify-between">
                                <span className="text-muted-foreground">Percentage:</span>
                                <span>{typeof pctEntry?.value === 'number' ? pctEntry.value.toFixed(1) : pctEntry?.value}%</span>
                              </div>
                              <div className="flex justify-between">
                                <span className="text-muted-foreground">Volume:</span>
                                <span>{volEntry?.value?.toLocaleString()}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                );
              }}
            />
            <Legend />
            {selectedSegments.map(segmentId => {
              const segment = segments.find(s => s.id === segmentId);
              if (!segment) return null;

              return (
                <g key={segmentId}>
                  <Line
                    type="monotone"
                    dataKey={`${segmentId}_pct`}
                    name={`${segment.name} %`}
                    yAxisId="percentage"
                    stroke={segment.color}
                    strokeWidth={2}
                    dot={false}
                  />
                  <Bar
                    dataKey={`${segmentId}_vol`}
                    name={`${segment.name} Volume`}
                    yAxisId="volume"
                    fill={segment.color}
                    opacity={0.2}
                  />
                </g>
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}