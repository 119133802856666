import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { IndustrySegments } from '../components/industry-segments';
import { CompanyTracker } from '../components/company-tracker';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Badge } from '@/components/ui/badge';
import type { DateRange } from 'react-day-picker';

interface IndustryProps {
  dateRange?: DateRange;
}

export function Industry({ dateRange }: IndustryProps) {
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <div className="flex items-center gap-2">
            <h2 className="text-3xl font-bold tracking-tight">Industry Movements</h2>
            <Badge className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white border-0">
              PRO
            </Badge>
          </div>
          <p className="text-muted-foreground">
            Track industry segments and company performance
          </p>
        </div>
      </div>

      <Tabs defaultValue="segments" className="space-y-4">
        <TabsList>
          <TabsTrigger value="segments">Industry Segments</TabsTrigger>
          <TabsTrigger value="companies">Company Tracker</TabsTrigger>
        </TabsList>

        <TabsContent value="segments">
          <Card>
            <CardHeader>
              <CardTitle>Segment Performance</CardTitle>
              <CardDescription>
                Track performance across different industry segments
              </CardDescription>
            </CardHeader>
            <CardContent>
              <IndustrySegments dateRange={dateRange} />
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="companies">
          <Card>
            <CardHeader>
              <CardTitle>Company Performance</CardTitle>
              <CardDescription>
                Monitor and compare company metrics
              </CardDescription>
            </CardHeader>
            <CardContent>
              <CompanyTracker dateRange={dateRange} />
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}