import { useState } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { format, parseISO } from 'date-fns';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { ChartSkeleton } from './chart-skeleton';
import { ChartWatermark } from './chart-watermark';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

const TOPICS = [
  'All Topics',
  'ETF',
  'Mining',
  'Regulation',
  'Adoption',
  'DeFi',
  'Security',
] as const;

const SOURCES = [
  'All Sources',
  'Financial Media',
  'Social Media',
  'Communities',
  'Legacy Media',
] as const;

const SENTIMENT_COLORS = {
  Positive: '#22c55e',
  Neutral: '#3b82f6',
  Negative: '#ef4444',
} as const;

export function MediaPerformance() {
  const [selectedTopic, setSelectedTopic] = useState<string>('All Topics');
  const [selectedSource, setSelectedSource] = useState<string>('All Sources');
  const [isLoading] = useState(false);

  // Generate mock data
  const data = generateMockData();

  if (isLoading) {
    return <ChartSkeleton />;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-end gap-4">
        <Select value={selectedTopic} onValueChange={setSelectedTopic}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select topic" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Topics</SelectLabel>
              {TOPICS.map(topic => (
                <SelectItem key={topic} value={topic}>
                  {topic}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>

        <Select value={selectedSource} onValueChange={setSelectedSource}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select source" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Sources</SelectLabel>
              {SOURCES.map(source => (
                <SelectItem key={source} value={source}>
                  {source}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <ChartWatermark />
            <XAxis
              dataKey="date"
              tickFormatter={(value) => format(parseISO(value), 'MMM d')}
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              domain={[0, 100]}
              tickFormatter={(value) => `${value}%`}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (!active || !payload?.length) return null;

                return (
                  <Card className={cn(
                    "p-2 shadow-lg border-primary/20",
                    "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
                  )}>
                    <div className="text-sm font-medium">
                      {format(parseISO(label), 'MMMM d, yyyy')}
                    </div>
                    <div className="mt-1 space-y-1">
                      {payload.map((entry: any) => (
                        <div key={entry.name} className="flex items-center gap-2">
                          <div 
                            className="w-2 h-2 rounded-full"
                            style={{ backgroundColor: entry.color }}
                          />
                          <span className="text-sm">
                            {entry.name}: {entry.value.toFixed(1)}%
                          </span>
                        </div>
                      ))}
                    </div>
                  </Card>
                );
              }}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="positive"
              name="Positive"
              stroke={SENTIMENT_COLORS.Positive}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="neutral"
              name="Neutral"
              stroke={SENTIMENT_COLORS.Neutral}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="negative"
              name="Negative"
              stroke={SENTIMENT_COLORS.Negative}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function generateMockData() {
  const data = [];
  const today = new Date();
  let positive = 50;
  let neutral = 30;
  let negative = 20;

  for (let i = 30; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(date.getDate() - i);

    // Add some random variation while maintaining total of 100%
    const posChange = (Math.random() - 0.5) * 4;
    const neuChange = (Math.random() - 0.5) * 4;
    const negChange = -(posChange + neuChange);

    positive = Math.max(0, Math.min(100, positive + posChange));
    neutral = Math.max(0, Math.min(100, neutral + neuChange));
    negative = Math.max(0, Math.min(100, negative + negChange));

    // Normalize to ensure sum is 100%
    const total = positive + neutral + negative;
    positive = (positive / total) * 100;
    neutral = (neutral / total) * 100;
    negative = (negative / total) * 100;

    data.push({
      date: format(date, 'yyyy-MM-dd'),
      positive,
      neutral,
      negative,
    });
  }

  return data;
}