import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format, subDays, addDays } from 'date-fns';
import { useVolumeData } from '@/hooks/use-volume-data';
import { Card } from '@/components/ui/card';
import type { DateRange } from 'react-day-picker';
import { ShareButton } from './share-button';
import { cn } from '@/lib/utils';

interface VolumeData {
  date: string;
  volume: number;
}

interface OverviewProps {
  dateRange?: DateRange;
  comparisonPeriod?: 'none' | 'previous' | 'year';
}

export function Overview({ dateRange, comparisonPeriod = 'none' }: OverviewProps) {
  const { data: currentData } = useVolumeData({ dateRange });
  
  // Fetch comparison data if needed
  const { data: comparisonData } = useVolumeData({
    dateRange: comparisonPeriod === 'previous' 
      ? {
          from: dateRange?.from ? subDays(dateRange.from, 7) : undefined,
          to: dateRange?.to ? subDays(dateRange.to, 7) : undefined,
        }
      : comparisonPeriod === 'year'
      ? {
          from: dateRange?.from ? addDays(dateRange.from, -365) : undefined,
          to: dateRange?.to ? addDays(dateRange.to, -365) : undefined,
        }
      : undefined
  });

  const safeCurrentData = Array.isArray(currentData) ? currentData : [];
  const safeComparisonData = Array.isArray(comparisonData) ? comparisonData : [];

  const combinedData = safeCurrentData.map((item: VolumeData, index: number) => ({
    date: item.date,
    current: item.volume,
    previous: comparisonPeriod !== 'none' ? safeComparisonData[index]?.volume : undefined
  }));

  const metrics = [
    { 
      label: 'Total Volume', 
      value: `${safeCurrentData.reduce((sum, d) => sum + (d.volume || 0), 0).toLocaleString()} mentions` 
    },
    { 
      label: 'Daily Average', 
      value: `${Math.round(
        safeCurrentData.reduce((sum, d) => sum + (d.volume || 0), 0) / 
        (safeCurrentData.length || 1)
      ).toLocaleString()} mentions` 
    },
    { 
      label: 'Peak Volume', 
      value: `${Math.max(...safeCurrentData.map(d => d.volume || 0)).toLocaleString()} mentions` 
    }
  ];

  return (
    <div className="space-y-4" id="volume-chart">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            <div className="h-4 w-1 bg-gradient-to-b from-[#22c55e] to-[#3b82f6] rounded-full" />
            <h3 className="text-lg font-medium">Bitcoin Discussion Volume</h3>
          </div>
          <p className="text-sm text-muted-foreground">
            Mentions across all tracked sources
          </p>
        </div>
        <div className="p-1">
          <ShareButton 
            chartId="volume-chart"
            title="Bitcoin Discussion Volume"
            metrics={metrics}
          />
        </div>
      </div>

      <div className="relative">
        <ResponsiveContainer width="100%" height={350}>
          <AreaChart data={combinedData}>
            <defs>
              <linearGradient id="currentGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#22c55e" stopOpacity={0.3} />
                <stop offset="50%" stopColor="#3b82f6" stopOpacity={0.15} />
                <stop offset="100%" stopColor="#3b82f6" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="previousGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#8b5cf6" stopOpacity={0.2} />
                <stop offset="100%" stopColor="#8b5cf6" stopOpacity={0} />
              </linearGradient>
              <filter id="glow">
                <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
                <feMerge>
                  <feMergeNode in="coloredBlur"/>
                  <feMergeNode in="SourceGraphic"/>
                </feMerge>
              </filter>
              <pattern id="grid" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <path d="M 20 0 L 0 0 0 20" fill="none" stroke="currentColor" strokeWidth="0.5" opacity="0.1"/>
              </pattern>
            </defs>
            <XAxis
              dataKey="date"
              stroke="#94a3b8"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => format(new Date(value), 'MMM d')}
              dy={10}
            />
            <YAxis
              stroke="#94a3b8"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${(value / 1000).toFixed(1)}k`}
              dx={-10}
            />
            <Tooltip
              content={({ active, payload }) => {
                if (!active || !payload?.length) return null;
                
                return (
                  <Card className={cn(
                    "p-2 shadow-lg border-primary/20",
                    "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
                  )}>
                    <div className="text-sm font-medium">
                      {format(new Date(payload[0].payload.date), 'MMMM d, yyyy')}
                    </div>
                    <div className="mt-1 space-y-1">
                      <div className="flex items-center gap-2">
                        <div className="w-2 h-2 rounded-full bg-[#22c55e]" />
                        <span className="text-sm">
                          {payload[0].value?.toLocaleString()} mentions
                        </span>
                      </div>
                      {comparisonPeriod !== 'none' && payload[1]?.value && (
                        <div className="flex items-center gap-2">
                          <div className="w-2 h-2 rounded-full bg-[#8b5cf6]" />
                          <span className="text-sm">
                            {payload[1].value?.toLocaleString()} mentions
                          </span>
                        </div>
                      )}
                    </div>
                  </Card>
                );
              }}
            />
            {comparisonPeriod !== 'none' && (
              <Area
                type="monotone"
                dataKey="previous"
                stroke="#8b5cf6"
                strokeWidth={1.5}
                fillOpacity={1}
                fill="url(#previousGradient)"
                dot={false}
              />
            )}
            <Area
              type="monotone"
              dataKey="current"
              stroke="#22c55e"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#currentGradient)"
              dot={false}
              style={{ filter: 'url(#glow)' }}
            />
          </AreaChart>
        </ResponsiveContainer>

        {/* Animated gradient background */}
        <div 
          className="absolute inset-0 pointer-events-none opacity-[0.02] dark:opacity-[0.05]"
          style={{
            background: `
              linear-gradient(to right, currentColor 1px, transparent 1px) 0 0 / 50px 100%,
              linear-gradient(to bottom, currentColor 1px, transparent 1px) 0 0 / 100% 50px
            `,
            mask: 'linear-gradient(to bottom, transparent, black 10%, black 90%, transparent)'
          }}
        />
      </div>
    </div>
  );
}