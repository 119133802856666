import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { ChartSkeleton } from "./chart-skeleton";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { RefreshCw } from "lucide-react";
import type { DateRange } from "react-day-picker";

interface FearAndGreedProps {
  dateRange?: DateRange;
}

export function FearAndGreed({ dateRange }: FearAndGreedProps) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['fear-and-greed', dateRange],
    queryFn: async () => {
      if (!dateRange?.from || !dateRange?.to) return null;
      
      const startDate = format(dateRange.from, 'yyyy-MM-dd');
      const endDate = format(dateRange.to, 'yyyy-MM-dd');
      
      const url = new URL('fear-greed-index', 'https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/');
      url.searchParams.append('startDate', startDate);
      url.searchParams.append('endDate', endDate);
      
      const response = await fetch(url.toString());
      if (!response.ok) {
        const text = await response.text();
        throw new Error(`API Error (${response.status}): ${text}`);
      }
      
      return response.json();
    },
    enabled: !!dateRange?.from && !!dateRange?.to,
  });

  if (isLoading) return <ChartSkeleton />;

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription className="flex items-center justify-between">
          <span>Failed to load fear and greed data</span>
          <Button variant="outline" size="sm" onClick={() => refetch()}>
            <RefreshCw className="h-4 w-4 mr-2" />
            Retry
          </Button>
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="h-[400px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <XAxis 
            dataKey="date" 
            tickFormatter={(value) => format(new Date(value), 'MMM d')}
          />
          <YAxis domain={[0, 100]} />
          <Tooltip 
            labelFormatter={(value) => format(new Date(value), 'MMM d, yyyy')}
          />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="value" 
            stroke="#8884d8" 
            name="Fear & Greed Index"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}