import { ResponsiveContainer, ComposedChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { format } from 'date-fns';
import { useChartData } from '@/hooks/use-chart-data';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { Skeleton } from '@/components/ui/skeleton';

interface SentimentPriceProps {
  dateRange?: DateRange;
}

interface PriceData {
  date: string;
  price: number;
  sentiment: number;
}

export function SentimentPrice({ dateRange }: SentimentPriceProps) {
  const { data, isLoading, error } = useChartData('sentiment-price', dateRange);

  const chartData = useMemo(() => {
    if (!data) return [];
    return data.map((item: PriceData) => ({
      date: new Date(item.date),
      price: item.price,
      sentiment: item.sentiment,
    }));
  }, [data]);

  if (isLoading) return <Skeleton className="w-full h-[300px]" />;
  if (error) throw error;

  return (
    <div className="w-full h-[300px]">
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={chartData}>
          <XAxis
            dataKey="date"
            tickFormatter={(date) => format(date, 'MMM dd')}
            scale="time"
            type="number"
            domain={['auto', 'auto']}
          />
          <YAxis yAxisId="price" orientation="right" />
          <YAxis yAxisId="sentiment" orientation="left" />
          <Tooltip
            content={({ active, payload }) => {
              if (!active || !payload?.length) return null;
              const data = payload[0].payload;
              return (
                <div className="bg-background border rounded-lg p-2 shadow-lg">
                  <p className="text-sm font-medium">
                    {format(data.date, 'MMM dd, yyyy')}
                  </p>
                  <p className="text-sm">
                    Price: ${data.price.toLocaleString()}
                  </p>
                  <p className="text-sm">
                    Sentiment: {data.sentiment.toFixed(2)}
                  </p>
                </div>
              );
            }}
          />
          <Line
            yAxisId="price"
            type="monotone"
            dataKey="price"
            stroke="#8884d8"
            dot={false}
          />
          <Line
            yAxisId="sentiment"
            type="monotone"
            dataKey="sentiment"
            stroke="#82ca9d"
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}