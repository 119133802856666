import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { SentimentTimeline } from '../components/sentiment-timeline';
import { SentimentOverview } from '../components/sentiment-overview';
import { NarrativeDistribution } from '../components/narrative-distribution';
import { ShareButton } from '../components/share-button';
import type { DateRange } from 'react-day-picker';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { ErrorBoundary } from 'react-error-boundary';
import { SentimentEvolution } from '../components/sentiment-evolution';

interface SentimentProps {
  dateRange?: DateRange;
}

export function Sentiment({ dateRange: initialDateRange }: SentimentProps) {
  const [chartErrors, setChartErrors] = useState<Record<string, string>>({});
  const [selectedTopic, setSelectedTopic] = useState<string>('all');
  const [availableTopics, setAvailableTopics] = useState<string[]>([]);

  const timelineMetrics = [
    { label: 'Market Sentiment', value: '67% Bullish' },
    { label: 'Momentum', value: '+2.3% Shift' },
    { label: 'Discussion Volume', value: '12.5K mentions' },
  ];

  const overviewMetrics = [
    { label: 'Market Consensus', value: '72% Bullish' },
    { label: 'Signal Strength', value: '0.85' },
    { label: 'Data Coverage', value: '25K sources' },
  ];

  const channelMetrics = [
    { label: 'Leading Channel', value: 'Social Media' },
    { label: 'Engagement', value: '8.2K interactions' },
    { label: 'Impact Score', value: '78%' },
  ];

  const renderChart = (Component: React.ComponentType<{ 
    dateRange?: DateRange;
    onError?: (error: string) => void;
  }>, chartId: string) => {
    if (chartErrors[chartId]) {
      return (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>
            {chartErrors[chartId]}
          </AlertDescription>
        </Alert>
      );
    }

    return (
      <Component 
        dateRange={initialDateRange} 
        onError={(error) => setChartErrors(prev => ({ ...prev, [chartId]: error }))}
      />
    );
  };

  useEffect(() => {
    async function fetchTopics() {
      if (!initialDateRange?.from || !initialDateRange?.to) return;

      try {
        const startDate = format(initialDateRange.from, 'yyyy-MM-dd');
        const endDate = format(initialDateRange.to, 'yyyy-MM-dd');

        const response = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/topic-sentiment?startDate=${startDate}&endDate=${endDate}`
        );
        
        if (!response.ok) {
          throw new Error('Failed to fetch topics');
        }

        const data = await response.json();
        
        const topics = Array.from(new Set(data
          .filter((item: any) => 
            item.topic && 
            item.topic.trim() !== '' && 
            item.mention_volume > 3 &&
            item.topic !== 'Uncategorized'
          )
          .map((item: any) => item.topic))) as string[];

        console.log('Available topics:', topics);
        setAvailableTopics(topics);
      } catch (error) {
        console.error('Failed to fetch topics:', error);
        setAvailableTopics([]);
      }
    }

    fetchTopics();
  }, [initialDateRange]);

  const ErrorFallback = ({ error }: { error: Error }) => (
    <Alert variant="destructive">
      <AlertDescription>
        Failed to load sentiment data: {error.message}
      </AlertDescription>
    </Alert>
  );

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <div className="flex items-center gap-2">
            <h2 className="text-2xl sm:text-3xl font-bold tracking-tight">Market Sentiment Pulse</h2>
          </div>
          <p className="text-sm text-muted-foreground">
            Decode market psychology and spot sentiment shifts before they impact price
          </p>
        </div>
      </div>

      <Tabs defaultValue="overview" className="space-y-6">
        <TabsList>
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="timeline">Timeline</TabsTrigger>
          <TabsTrigger value="distribution">Distribution</TabsTrigger>
        </TabsList>

        <TabsContent value="overview">
          <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
            <Card className="min-h-[500px]">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <div>
                  <CardTitle>Sentiment Evolution</CardTitle>
                  <CardDescription>
                    Track sentiment shifts and predict market moves
                  </CardDescription>
                </div>
                <ShareButton 
                  chartId="sentiment-timeline"
                  title="Bitcoin Sentiment Evolution"
                  metrics={timelineMetrics}
                />
              </CardHeader>
              <CardContent className="pt-6">
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >
                  <SentimentEvolution dateRange={initialDateRange} />
                </ErrorBoundary>
              </CardContent>
            </Card>

            <Card className="min-h-[500px]">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <div>
                  <CardTitle>Market Sentiment Snapshot</CardTitle>
                  <CardDescription>
                  A 24-hour pulse on market psychology
                  </CardDescription>
                </div>
                <ShareButton 
                  chartId="sentiment-overview"
                  title="Bitcoin Market Consensus"
                  metrics={overviewMetrics}
                />
              </CardHeader>
              <CardContent className="pt-6">
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <SentimentOverview />
                </ErrorBoundary>
              </CardContent>
            </Card>
          </div>
        </TabsContent>

        <TabsContent value="timeline">
          <Card className="min-h-[500px]">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <div>
                <CardTitle>Sentiment Timeline</CardTitle>
                <CardDescription>
                  Track sentiment evolution over time
                </CardDescription>
              </div>
              <ShareButton 
                chartId="sentiment-timeline"
                title="Bitcoin Sentiment Timeline"
                metrics={timelineMetrics}
              />
            </CardHeader>
            <CardContent className="pt-6">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <SentimentTimeline 
                  dateRange={initialDateRange}
                  selectedTopic={selectedTopic}
                  onTopicChange={setSelectedTopic}
                  availableTopics={availableTopics}
                  showTopicSelector={true}
                />
              </ErrorBoundary>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="distribution">
          <Card className="min-h-[500px]">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <div>
                <CardTitle>Narrative Distribution</CardTitle>
                <CardDescription>
                  Identify which channels drive market sentiment
                </CardDescription>
              </div>
              <ShareButton 
                chartId="narrative-distribution"
                title="Bitcoin Narrative Distribution"
                metrics={channelMetrics}
              />
            </CardHeader>
            <CardContent className="pt-6 h-[500px]">
              {renderChart(NarrativeDistribution, 'distribution')}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}