import { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { TrendingUp, TrendingDown, Minus, ArrowRight, ExternalLink } from 'lucide-react';
import { cn } from '@/lib/utils';

const trendDiscoveries = [
  {
    id: 1,
    title: "ETF Approval Impact",
    change: "+28%",
    trend: "up",
    description: "Significant increase in ETF-related discussions",
    details: {
      keyInsights: [
        "75% positive sentiment in institutional discussions",
        "Major focus on regulatory compliance",
        "Growing interest in spot ETF prospects"
      ],
      relatedPeople: [
        { name: "Gary Gensler", role: "SEC Chair" },
        { name: "Cathie Wood", role: "ARK Invest" }
      ],
      relatedOutlets: [
        { name: "Bloomberg", type: "Financial Media" },
        { name: "SEC", type: "Regulatory" }
      ],
      perceptionIndex: 85,
      url: "https://example.com/btc-etf-analysis"
    }
  },
  {
    id: 2,
    title: "Mining Difficulty",
    change: "+15%",
    trend: "up",
    description: "Rising discussions about mining economics",
    details: {
      keyInsights: [
        "Strong correlation with hashrate growth",
        "Increased focus on energy efficiency",
        "Growing interest in mining stocks"
      ],
      relatedPeople: [
        { name: "Michael Saylor", role: "MicroStrategy" },
        { name: "Fred Thiel", role: "Marathon Digital" }
      ],
      relatedOutlets: [
        { name: "The Block", type: "Crypto Media" },
        { name: "CoinDesk", type: "Crypto Media" }
      ],
      perceptionIndex: 65,
      url: "https://example.com/mining-analysis"
    }
  },
  {
    id: 3,
    title: "Market Volatility",
    change: "-5%",
    trend: "down",
    description: "Declining market uncertainty",
    details: {
      keyInsights: [
        "Reduced options volatility",
        "Stabilizing futures premiums",
        "Normalized funding rates"
      ],
      relatedPeople: [
        { name: "Sam Bankman-Fried", role: "Former FTX CEO" },
        { name: "Brian Armstrong", role: "Coinbase CEO" }
      ],
      relatedOutlets: [
        { name: "Financial Times", type: "Financial Media" },
        { name: "Wall Street Journal", type: "Financial Media" }
      ],
      perceptionIndex: 25,
      url: "https://example.com/volatility-analysis"
    }
  },
  {
    id: 4,
    title: "Institutional Adoption",
    change: "+12%",
    trend: "up",
    description: "Growing institutional interest in Bitcoin",
    details: {
      keyInsights: [
        "Major banks launching crypto services",
        "Increased corporate treasury adoption",
        "New institutional products"
      ],
      relatedPeople: [
        { name: "Larry Fink", role: "BlackRock CEO" },
        { name: "Jamie Dimon", role: "JPMorgan CEO" }
      ],
      relatedOutlets: [
        { name: "Reuters", type: "Financial Media" },
        { name: "CNBC", type: "Financial Media" }
      ],
      perceptionIndex: 78,
      url: "https://example.com/institutional-adoption"
    }
  }
];

export function BreakingInsights() {
  const [selectedTrend, setSelectedTrend] = useState<typeof trendDiscoveries[0] | null>(null);
  const [sheetOpen, setSheetOpen] = useState(false);

  const getTrendIcon = (trend: string) => {
    switch (trend) {
      case 'up':
        return <TrendingUp className="h-4 w-4 text-green-500" />;
      case 'down':
        return <TrendingDown className="h-4 w-4 text-red-500" />;
      default:
        return <Minus className="h-4 w-4 text-yellow-500" />;
    }
  };

  const getPerceptionBadge = (index: number) => {
    if (index >= 70) {
      return <div className="px-2 py-1 rounded-full bg-green-500 text-white text-sm">Positive</div>;
    }
    if (index >= 31) {
      return <div className="px-2 py-1 rounded-full bg-blue-500 text-white text-sm">Neutral</div>;
    }
    return <div className="px-2 py-1 rounded-full bg-red-500 text-white text-sm">Negative</div>;
  };

  const handleTrendClick = (trend: typeof trendDiscoveries[0]) => {
    setSelectedTrend(trend);
    setSheetOpen(true);
  };

  return (
    <div className="space-y-4" id="breaking-insights">
      <div>
        <h3 className="text-lg font-medium">Breaking Insights</h3>
        <p className="text-sm text-muted-foreground">
          Stay ahead with real-time market-moving developments
        </p>
      </div>

      <div className="space-y-3">
        {trendDiscoveries.map((trend) => (
          <div
            key={trend.id}
            className={cn(
              "flex items-center justify-between p-4 border rounded-lg",
              "hover:bg-accent cursor-pointer transition-colors"
            )}
            onClick={() => handleTrendClick(trend)}
          >
            <div className="flex items-center gap-3">
              {getTrendIcon(trend.trend)}
              <div>
                <h4 className="font-medium">{trend.title}</h4>
                <p className="text-sm text-muted-foreground">{trend.description}</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className={cn(
                "px-2 py-1 rounded-full text-sm font-medium",
                trend.trend === 'up' ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"
              )}>
                {trend.change}
              </div>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
            </div>
          </div>
        ))}
      </div>

      <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
        <SheetContent className="w-[400px] sm:w-[540px]">
          {selectedTrend && (
            <>
              <SheetHeader>
                <SheetTitle className="flex items-center gap-2">
                  {getTrendIcon(selectedTrend.trend)}
                  {selectedTrend.title}
                </SheetTitle>
                <SheetDescription>
                  Detailed trend analysis and insights
                </SheetDescription>
              </SheetHeader>
              <div className="mt-6 space-y-6">
                <div>
                  <h3 className="font-medium mb-2">Key Insights</h3>
                  <ul className="space-y-2">
                    {selectedTrend.details.keyInsights.map((insight, index) => (
                      <li key={index} className="text-sm text-muted-foreground">
                        • {insight}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="font-medium mb-2">Related People</h3>
                  <div className="space-y-2">
                    {selectedTrend.details.relatedPeople.map((person, index) => (
                      <div key={index} className="flex justify-between text-sm">
                        <span className="font-medium">{person.name}</span>
                        <span className="text-muted-foreground">{person.role}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h3 className="font-medium mb-2">Related Outlets</h3>
                  <div className="space-y-2">
                    {selectedTrend.details.relatedOutlets.map((outlet, index) => (
                      <div key={index} className="flex justify-between text-sm">
                        <span className="font-medium">{outlet.name}</span>
                        <span className="text-muted-foreground">{outlet.type}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h3 className="font-medium mb-2">Perception Index</h3>
                  <div className="flex items-center gap-2">
                    {getPerceptionBadge(selectedTrend.details.perceptionIndex)}
                    <span className="text-lg font-bold">
                      {selectedTrend.details.perceptionIndex}
                    </span>
                    <span className="text-sm text-muted-foreground">
                      / 100
                    </span>
                  </div>
                </div>
                <Button 
                  className="w-full"
                  onClick={() => window.open(selectedTrend.details.url, '_blank')}
                >
                  <ExternalLink className="mr-2 h-4 w-4" />
                  Read Full Analysis
                </Button>
              </div>
            </>
          )}
        </SheetContent>
      </Sheet>
    </div>
  );
}