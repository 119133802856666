import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { ChartSkeleton } from "./chart-skeleton";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { RefreshCw } from "lucide-react";
import type { DateRange } from "react-day-picker";

interface PricePerformanceProps {
  dateRange?: DateRange;
}

interface SentimentData {
  date: string;
  positive: number;
  negative: number;
  neutral: number;
  total: number;
}

interface FearGreedData {
  date: string;
  value: number;
  classification: string;
}

interface CombinedData {
  date: string;
  sentimentScore?: number;
  fearGreedValue?: number;
}

export function PricePerformance({ dateRange }: PricePerformanceProps) {
  // Fetch Sentiment data
  const sentimentQuery = useQuery({
    queryKey: ['sentiment-metrics', dateRange],
    queryFn: async () => {
      if (!dateRange?.from || !dateRange?.to) return null;
      
      const startDate = format(dateRange.from, 'yyyy-MM-dd');
      const endDate = format(dateRange.to, 'yyyy-MM-dd');
      
      const url = new URL('sentiment-metrics', 'https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/');
      url.searchParams.append('startDate', startDate);
      url.searchParams.append('endDate', endDate);

      const response = await fetch(url.toString());
      if (!response.ok) {
        const text = await response.text();
        throw new Error(`API Error (${response.status}): ${text}`);
      }
      
      return response.json() as Promise<SentimentData[]>;
    },
    enabled: !!dateRange?.from && !!dateRange?.to,
  });

  // Fetch Fear & Greed Index data
  const fearGreedQuery = useQuery({
    queryKey: ['fear-greed', dateRange],
    queryFn: async () => {
      if (!dateRange?.from || !dateRange?.to) return null;
      
      const startDate = format(dateRange.from, 'yyyy-MM-dd');
      const endDate = format(dateRange.to, 'yyyy-MM-dd');
      
      const url = new URL('fear-greed-index', 'https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/');
      url.searchParams.append('startDate', startDate);
      url.searchParams.append('endDate', endDate);

      const response = await fetch(url.toString());
      if (!response.ok) {
        const text = await response.text();
        throw new Error(`API Error (${response.status}): ${text}`);
      }
      
      return response.json() as Promise<FearGreedData[]>;
    },
    enabled: !!dateRange?.from && !!dateRange?.to,
  });

  const isLoading = sentimentQuery.isLoading || fearGreedQuery.isLoading;
  const error = sentimentQuery.error || fearGreedQuery.error;

  if (isLoading) return <ChartSkeleton />;

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription className="flex items-center justify-between">
          <span>Failed to load performance data</span>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => {
              sentimentQuery.refetch();
              fearGreedQuery.refetch();
            }}
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            Retry
          </Button>
        </AlertDescription>
      </Alert>
    );
  }

  // Combine the datasets
  const dataMap = new Map<string, CombinedData>();

  // Add sentiment data
  sentimentQuery.data?.forEach(item => {
    const total = item.total || 1; // Prevent division by zero
    const sentimentScore = ((item.positive - item.negative) / total) * 100;
    dataMap.set(item.date, { 
      date: item.date, 
      sentimentScore: Math.round(sentimentScore * 100) / 100 
    });
  });

  // Add fear & greed data
  fearGreedQuery.data?.forEach(item => {
    const existing = dataMap.get(item.date) || { date: item.date };
    existing.fearGreedValue = item.value;
    dataMap.set(item.date, existing);
  });

  // Convert map to array and sort by date
  const chartData = Array.from(dataMap.values())
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <div className="h-[400px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData} margin={{ right: 60 }}>
          <XAxis 
            dataKey="date" 
            tickFormatter={(value) => format(new Date(value), 'MMM d')}
          />
          <YAxis 
            yAxisId="sentiment"
            orientation="left"
            domain={[-100, 100]}
            label={{ value: 'Sentiment Score', angle: -90, position: 'insideLeft' }}
          />
          <YAxis 
            yAxisId="fear-greed"
            orientation="right"
            domain={[0, 100]}
            label={{ value: 'Fear & Greed Index', angle: 90, position: 'insideRight' }}
          />
          <Tooltip 
            labelFormatter={(value) => format(new Date(value), 'MMM d, yyyy')}
            formatter={(value: number) => [`${value.toFixed(2)}`, '']}
          />
          <Legend />
          <Line 
            yAxisId="sentiment"
            type="monotone" 
            dataKey="sentimentScore" 
            stroke="#8884d8" 
            name="Sentiment Score"
            dot={false}
          />
          <Line 
            yAxisId="fear-greed"
            type="monotone" 
            dataKey="fearGreedValue" 
            stroke="#82ca9d" 
            name="Fear & Greed Index"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
} 