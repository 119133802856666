import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { TopicTrends } from '../components/topic-trends';
import type { DateRange } from 'react-day-picker';
import { subMonths, startOfDay, endOfDay, isAfter, isFuture, subDays } from 'date-fns';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertCircle, Loader2 } from 'lucide-react';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';

interface TopicsProps {
  dateRange?: DateRange;
}

export function Topics({ dateRange }: TopicsProps) {
  // Set up date constraints
  const today = startOfDay(new Date());
  const maxEndDate = endOfDay(subDays(today, 1)); // Only allow up to yesterday
  const minStartDate = startOfDay(subMonths(today, 3));

  // Provide default date range if none is provided - limit to last 30 days
  const defaultDateRange: DateRange = {
    from: startOfDay(subDays(today, 30)),
    to: maxEndDate,
  };

  // Ensure we have valid dates and they're within bounds
  const effectiveDateRange: DateRange = {
    from: dateRange?.from 
      ? startOfDay(
          isAfter(dateRange.from, minStartDate) 
            ? dateRange.from 
            : minStartDate
        )
      : defaultDateRange.from,
    to: dateRange?.to
      ? startOfDay(
          isFuture(dateRange.to) || isAfter(dateRange.to, maxEndDate)
            ? maxEndDate
            : dateRange.to
        )
      : defaultDateRange.to,
  };
  
  // Validate date range
  const isValidDateRange = effectiveDateRange.from instanceof Date && 
    !isNaN(effectiveDateRange.from.getTime()) &&
    effectiveDateRange.to instanceof Date && 
    !isNaN(effectiveDateRange.to.getTime()) &&
    effectiveDateRange.from <= effectiveDateRange.to &&
    !isFuture(effectiveDateRange.from) &&
    !isFuture(effectiveDateRange.to);

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <div className="flex items-center gap-2">
            <h2 className="text-3xl font-bold tracking-tight">Topic Intelligence</h2>
            <Badge variant="outline" className="animate-pulse bg-green-500/20 text-green-500 border-green-500">Live</Badge>
          </div>
          <p className="text-muted-foreground">
            Deep dive into topic relationships and sentiment patterns
          </p>
        </div>
      </div>

      {!isValidDateRange ? (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Please select a valid date range to view topic trends.
          </AlertDescription>
        </Alert>
      ) : (
        <Card>
          <CardHeader>
            <CardTitle>Topic Evolution</CardTitle>
            <CardDescription>
              Track how topics emerge, peak, and fade over time
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ErrorBoundary
              FallbackComponent={({ error, resetErrorBoundary }) => (
                <div className="flex h-[400px] w-full flex-col items-center justify-center gap-4">
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription>
                      Error loading topic trends: {error.message}
                    </AlertDescription>
                  </Alert>
                  <button 
                    onClick={resetErrorBoundary}
                    className="rounded bg-primary px-4 py-2 text-primary-foreground"
                  >
                    Retry
                  </button>
                </div>
              )}
            >
              <Suspense fallback={
                <div className="flex h-[400px] w-full items-center justify-center">
                  <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
                </div>
              }>
                <TopicTrends dateRange={effectiveDateRange} />
              </Suspense>
            </ErrorBoundary>
          </CardContent>
        </Card>
      )}
    </div>
  );
}