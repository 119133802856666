export interface ChannelVolumeResponse {
  channel_category: string;
  total_entries: number;
  percentage: number;
  percentage_change?: number;
}

export const VALID_CATEGORIES = [
  'Bitcoin Repositories',
  'Communities',
  'Crypto Media',
  'Financial Media',
  'Legacy Media',
  'Social Media',
] as const;

export async function fetchChannelVolumeData(startDate: string, endDate: string): Promise<ChannelVolumeResponse[]> {
  try {
    console.debug('[Channel Volume] Fetching data:', { startDate, endDate });
    
    const url = new URL('https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/channel-volume');
    url.searchParams.append('startDate', startDate);
    url.searchParams.append('endDate', endDate);
    
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      console.error(`[Channel Volume] API request failed with status ${response.status}`);
      return [];
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      console.error('[Channel Volume] Invalid response format:', data);
      return [];
    }

    // Validate data structure
    const isValidData = data.every(item => 
      item && 
      typeof item === 'object' && 
      typeof item.channel_category === 'string' &&
      typeof item.total_entries === 'number'
    );

    if (!isValidData) {
      console.error('[Channel Volume] Invalid data structure:', data);
      return [];
    }

    return normalizeVolumeData(data);
  } catch (error) {
    console.error('[Channel Volume] Error fetching data:', error);
    return []; // Return empty array instead of throwing
  }
}

function normalizeVolumeData(data: ChannelVolumeResponse[]): ChannelVolumeResponse[] {
  if (!Array.isArray(data)) {
    console.error('Invalid data format received in normalizeVolumeData');
    return [];
  }

  const groupedData = data.reduce((acc, item) => {
    if (!item || typeof item !== 'object' || !item.channel_category) {
      return acc;
    }

    if (!acc[item.channel_category]) {
      acc[item.channel_category] = {
        channel_category: item.channel_category,
        total_entries: 0,
        percentage: 0
      };
    }
    acc[item.channel_category].total_entries += item.total_entries || 0;
    return acc;
  }, {} as Record<string, ChannelVolumeResponse>);

  const totalEntries = Object.values(groupedData)
    .reduce((sum, item) => sum + (item.total_entries || 0), 0);

  if (totalEntries === 0) {
    return [];
  }

  const processedData = Object.values(groupedData).map(item => ({
    ...item,
    percentage: Number(((item.total_entries / totalEntries) * 100).toFixed(1))
  }));

  return processedData.sort((a, b) => b.percentage - a.percentage);
}