import { useQuery } from '@tanstack/react-query';
import { getFearGreedIndex } from '@/lib/api/fear-greed';
import { getBitcoinPrice } from '@/lib/api/crypto-compare';

export function useMarketMetrics() {
  const today = new Date().toISOString().split('T')[0];
  
  return useQuery({
    queryKey: ['market-metrics', today],
    queryFn: async () => {
      try {
        const [fearGreedData, btcPrice] = await Promise.all([
          getFearGreedIndex({
            startDate: today,
            endDate: today,
          }),
          getBitcoinPrice()
        ]);
        
        console.log('Fear Greed Response:', fearGreedData);
        
        if (!fearGreedData || !Array.isArray(fearGreedData) || fearGreedData.length === 0) {
          throw new Error('Invalid API response format');
        }

        const latestData = fearGreedData[fearGreedData.length - 1];
        console.log('Latest Fear Greed Data:', latestData);
        
        return {
          price: btcPrice,
          confidence: Number(latestData?.fear_greed_index || 0),
          sentiment: getSentimentLabel(Number(latestData?.fear_greed_index || 0)),
          sentimentColor: getSentimentColor(Number(latestData?.fear_greed_index || 0)),
          drivers: 4,
        };
      } catch (error) {
        console.error('Error fetching market metrics:', error);
        throw error;
      }
    },
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
  });
}

function getSentimentLabel(value: number): string {
  if (value <= 20) return 'Extreme Fear';
  if (value <= 40) return 'Fear';
  if (value <= 60) return 'Neutral';
  if (value <= 80) return 'Greed';
  return 'Extreme Greed';
}

function getSentimentColor(value: number): string {
  if (value <= 20) return 'text-red-500';
  if (value <= 40) return 'text-orange-500';
  if (value <= 60) return 'text-yellow-500';
  if (value <= 80) return 'text-green-500';
  return 'text-emerald-500';
}