import { useChartData } from '@/hooks/use-chart-data';
import type { DateRange } from 'react-day-picker';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';
import { useMemo } from 'react';

interface NarrativeDistributionProps {
  dateRange?: DateRange;
  onError?: (error: string) => void;
}

// Custom colors for different channels
const COLORS = {
  'Social Media': '#2563eb',
  'Communities': '#16a34a',
  'Crypto Media': '#9333ea',
  'Financial Media': '#ea580c',
  'Legacy Media': '#0891b2',
  'Tech Media': '#be123c',
  'Bitcoin Repositories': '#854d0e',
  'Unknown': '#525252',
};

export function NarrativeDistribution({ dateRange, onError }: NarrativeDistributionProps) {
  const { data, isLoading, error } = useChartData('channel-volume-with-change', dateRange);

  console.log('Raw Narrative Distribution Data:', data);

  const chartData = useMemo(() => {
    if (!data || !Array.isArray(data)) {
      console.log('Invalid data format:', data);
      return [];
    }

    try {
      // Group data by date
      const groupedByDate = data.reduce((acc: Record<string, any>, curr: any) => {
        // Handle both possible date formats
        const date = typeof curr.date === 'object' ? curr.date.value : curr.date;
        
        if (!date || !curr.channel_category) {
          console.log('Skipping invalid data point:', curr);
          return acc;
        }

        if (!acc[date]) {
          acc[date] = { date };
        }

        const entries = Number(curr.total_entries || 0);
        const percentage = Number(curr.percentage || 0);

        acc[date][curr.channel_category] = entries;
        acc[date][`${curr.channel_category}_pct`] = percentage;

        return acc;
      }, {});

      console.log('Grouped data:', groupedByDate);

      // Convert to array and sort by date
      const sortedData = Object.values(groupedByDate).sort((a: any, b: any) => 
        new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      console.log('Sorted chart data:', sortedData);
      return sortedData;
    } catch (error) {
      console.error('Error processing chart data:', error);
      return [];
    }
  }, [data]);

  const channels = useMemo(() => {
    if (!data || !Array.isArray(data)) return [];
    
    const uniqueChannels = Array.from(new Set(
      data
        .filter(item => item && item.channel_category)
        .map(item => item.channel_category)
    )).filter(channel => channel !== 'Unknown');

    console.log('Available channels:', uniqueChannels);
    return uniqueChannels;
  }, [data]);

  if (isLoading) {
    return <Skeleton className="w-full h-[400px]" />;
  }

  if (error) {
    console.error('Narrative Distribution Error:', error);
    onError?.(error instanceof Error ? error.message : 'Failed to load data');
    return (
      <Alert variant="destructive">
        <AlertDescription>
          {error instanceof Error ? error.message : 'Failed to load channel volume data'}
        </AlertDescription>
      </Alert>
    );
  }

  if (!chartData.length) {
    console.log('No chart data available');
    return (
      <Alert>
        <AlertDescription>No data available for the selected date range</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="w-full h-full">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 60,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date" 
            angle={-45}
            textAnchor="end"
            height={60}
            interval={0}
          />
          <YAxis />
          <Tooltip 
            formatter={(value: number, name: string, props: any) => {
              const percentage = props.payload[`${name}_pct`];
              return [`${value} entries (${percentage?.toFixed(1)}%)`, name];
            }}
          />
          <Legend />
          {channels.map((channel) => (
            <Bar
              key={channel}
              dataKey={channel}
              name={channel}
              fill={COLORS[channel as keyof typeof COLORS] || '#888888'}
              stackId="a"
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}