import { SkeletonItem } from "@/components/ui/skeleton-item";

interface ChartSkeletonProps {
  className?: string;
}

export function ChartSkeleton({ className }: ChartSkeletonProps) {
  return <div className={className}>
    <SkeletonItem className="w-full h-full" />
  </div>;
}