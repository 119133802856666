import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PricePerformance } from "@/components/dashboard/components/price-performance";
import { BitcoinProxies } from "@/components/dashboard/components/bitcoin-proxies";
import { FearAndGreed } from "@/components/dashboard/components/fear-and-greed";
import { SentimentPrice } from "../components/sentiment-price";
import type { DateRange } from "react-day-picker";
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';
import { ChartLoader } from '@/components/ui/chart-loader';

interface PricePageProps {
  dateRange?: DateRange;
}

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="flex h-[300px] w-full items-center justify-center">
      <p className="text-destructive">Error: {error.message}</p>
    </div>
  );
}

export function PricePage({ dateRange }: PricePageProps) {
  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">Price Analysis</h2>
      </div>
      <Tabs defaultValue="performance" className="space-y-4">
        <TabsList>
          <TabsTrigger value="performance">Performance</TabsTrigger>
          <TabsTrigger value="proxies">Bitcoin Proxies</TabsTrigger>
          <TabsTrigger value="fear-greed">Fear and Greed</TabsTrigger>
          <TabsTrigger value="sentiment">Sentiment</TabsTrigger>
        </TabsList>
        <TabsContent value="performance" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Price Performance</CardTitle>
            </CardHeader>
            <CardContent className="pl-2">
              <PricePerformance dateRange={dateRange} />
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="proxies" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Bitcoin Proxies</CardTitle>
            </CardHeader>
            <CardContent className="pl-2">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ChartLoader />}>
                  <BitcoinProxies dateRange={dateRange} />
                </Suspense>
              </ErrorBoundary>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="fear-greed" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Fear and Greed Index</CardTitle>
            </CardHeader>
            <CardContent className="pl-2">
              <FearAndGreed dateRange={dateRange} />
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="sentiment" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>Price vs Sentiment</CardTitle>
            </CardHeader>
            <CardContent className="pl-2">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<ChartLoader />}>
                  <SentimentPrice dateRange={dateRange} />
                </Suspense>
              </ErrorBoundary>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}