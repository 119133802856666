import { Card, CardContent } from '@/components/ui/card';
import { useMarketMetrics } from '@/hooks/use-market-metrics';
import { Skeleton } from '@/components/ui/skeleton';
import { HelpCircle } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

export function MarketMetrics() {
  const { data, isLoading, error } = useMarketMetrics();
  
  console.log('Market Metrics Data:', data);

  if (error) {
    console.error('Market Metrics Error:', error);
    return <div>Error loading market metrics</div>;
  }

  if (isLoading) {
    return (
      <>
        {[...Array(3)].map((_, i) => (
          <Card key={i}>
            <CardContent className="py-4 pl-4 pr-3">
              <div className="flex flex-col space-y-1">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-8 w-32" />
              </div>
            </CardContent>
          </Card>
        ))}
      </>
    );
  }

  return (
    <>
      <Card>
        <CardContent className="py-5 px-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1.5">
              <p className="text-sm text-muted-foreground">Bitcoin Price</p>
            </div>
            <h2 className="text-2xl font-bold">
              ${(data?.price || 0).toLocaleString()}
            </h2>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="py-5 px-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1.5">
              <p className="text-sm text-muted-foreground">Market Confidence</p>
              <TooltipProvider delayDuration={100}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <HelpCircle className="h-3.5 w-3.5 text-muted-foreground cursor-help" />
                  </TooltipTrigger>
                  <TooltipContent side="top">
                    <p className="max-w-[200px]">Market sentiment indicator based on multiple factors including volatility, momentum, and social metrics</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-bold">{data?.confidence || '0'}</h2>
              <p className={`text-sm ${data?.sentimentColor}`}>
                {data?.sentiment}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="py-5 px-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1.5">
              <p className="text-sm text-muted-foreground">Narrative Drivers</p>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <HelpCircle className="h-3.5 w-3.5 text-muted-foreground" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Number of active market narratives currently influencing price action</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-bold">{data?.drivers || '4'}</h2>
              <p className="text-sm text-muted-foreground">active</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}