import { useQuery } from '@tanstack/react-query';
import { format, subDays } from 'date-fns';

interface CompanyData {
  date: string;
  [key: string]: number | string;
}

function generateMockData(companies: string[], days: number = 30): CompanyData[] {
  const data: CompanyData[] = [];
  const endDate = new Date();
  
  // Initialize starting prices for known companies
  const prices: { [key: string]: number } = {
    MSTR: 500,
    COIN: 150,
    RIOT: 20,
    MARA: 25,
    SI: 30,
  };

  // Initialize prices for custom companies
  companies.forEach(company => {
    if (!prices[company]) {
      prices[company] = 50 + Math.random() * 50;
    }
  });

  // Initialize momentum for each company
  const momentum: { [key: string]: number } = {};
  companies.forEach(company => {
    momentum[company] = 0;
  });

  for (let i = days; i >= 0; i--) {
    const date = format(subDays(endDate, i), 'yyyy-MM-dd');
    const dataPoint: CompanyData = { date };

    companies.forEach(company => {
      // Update momentum with some randomness
      momentum[company] = momentum[company] * 0.9 + (Math.random() - 0.5) * 2;
      
      // Calculate price change
      const change = momentum[company] + (Math.random() - 0.5) * 0.5;
      prices[company] = Math.max(1, prices[company] * (1 + change / 100));
      
      dataPoint[company] = Number(prices[company].toFixed(2));
    });

    data.push(dataPoint);
  }

  return data;
}

export function useCompanyData(companies: string[], days: number = 30) {
  return useQuery({
    queryKey: ['company-data', companies.join(','), days],
    queryFn: () => generateMockData(companies, days),
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // 30 minutes
  });
}