import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { MediaBenchmarks } from '../components/media-benchmarks';
import { MediaPerformance } from '../components/media-performance';
import { ShareButton } from '../components/share-button';

export function Media() {
  const performanceMetrics = [
    { label: 'Total Volume', value: '25.3K mentions' },
    { label: 'Peak Sentiment', value: '78% positive' },
    { label: 'Channel Coverage', value: '6 channels' }
  ];

  const benchmarkMetrics = [
    { label: 'Top Channel', value: 'Financial Media' },
    { label: 'Growth Rate', value: '+15.2%' },
    { label: 'Engagement Score', value: '8.7/10' }
  ];

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-3xl font-bold tracking-tight">Media Analysis</h2>
          <p className="text-muted-foreground">
            Track sentiment across different media channels
          </p>
        </div>
      </div>

      <Tabs defaultValue="performance" className="space-y-4">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="performance">Performance</TabsTrigger>
          <TabsTrigger value="benchmarks">Benchmarks</TabsTrigger>
        </TabsList>

        <TabsContent value="performance">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <div>
                <CardTitle>Media Performance</CardTitle>
                <CardDescription>
                  Track sentiment trends across different media channels
                </CardDescription>
              </div>
              <ShareButton 
                chartId="media-performance"
                title="Media Performance Analysis"
                metrics={performanceMetrics}
              />
            </CardHeader>
            <CardContent className="pt-6">
              <div className="h-[500px]">
                <MediaPerformance />
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="benchmarks">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <div>
                <CardTitle>Media Benchmarks</CardTitle>
                <CardDescription>
                  Compare performance metrics across different media channels
                </CardDescription>
              </div>
              <ShareButton 
                chartId="media-benchmarks"
                title="Media Benchmarks Analysis"
                metrics={benchmarkMetrics}
              />
            </CardHeader>
            <CardContent className="pt-6">
              <div className="h-[500px]">
                <MediaBenchmarks />
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}