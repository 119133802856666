import { parseISO, startOfDay } from 'date-fns';

export interface FearGreedResponse {
  date: string;
  fear_greed_index: number;
  status: 'Extreme Fear' | 'Fear' | 'Neutral' | 'Greed' | 'Extreme Greed';
  change: number;
  historicalData: {
    date: string;
    fear_greed_index: number;
    status: string;
  }[];
}

function aggregateDataByDay(data: any[]) {
  const dailyMap = new Map();

  data.forEach(item => {
    const day = startOfDay(parseISO(item.date)).toISOString();
    
    if (!dailyMap.has(day)) {
      dailyMap.set(day, { ...item, date: day });
    } else {
      const existing = dailyMap.get(day);
      dailyMap.set(day, {
        ...existing,
        fear_greed_index: (existing.fear_greed_index + item.fear_greed_index) / 2,
      });
    }
  });

  return Array.from(dailyMap.values())
    .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());
}

export async function fetchFearAndGreedData(startDate: string, endDate: string): Promise<FearGreedResponse> {
  try {
    const response = await fetch(
      `https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/fear-greed-index?startDate=${startDate}&endDate=${endDate}&userId=perception`,
      {
        headers: {
          'Accept': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch Fear & Greed Index: ${response.statusText}`);
    }

    const data = await response.json();
    
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Invalid Fear & Greed Index data format');
    }

    // Aggregate data by day
    const aggregatedData = aggregateDataByDay(data);
    
    // Get latest and previous day's data
    const latestData = aggregatedData[aggregatedData.length - 1];
    const previousData = aggregatedData[aggregatedData.length - 2] || latestData;

    // Calculate day-over-day change
    const change = latestData.fear_greed_index - previousData.fear_greed_index;

    // Determine status based on the index value
    const getStatus = (value: number): FearGreedResponse['status'] => {
      if (value <= 25) return 'Extreme Fear';
      if (value <= 45) return 'Fear';
      if (value <= 55) return 'Neutral';
      if (value <= 75) return 'Greed';
      return 'Extreme Greed';
    };

    // Process historical data
    const historicalData = aggregatedData.map(item => ({
      date: item.date,
      fear_greed_index: Number(item.fear_greed_index),
      status: getStatus(Number(item.fear_greed_index))
    }));

    return {
      date: latestData.date,
      fear_greed_index: Number(latestData.fear_greed_index),
      status: getStatus(Number(latestData.fear_greed_index)),
      change,
      historicalData
    };
  } catch (error) {
    console.error('Error fetching Fear & Greed data:', error);
    throw error;
  }
}

interface FearGreedParams {
  startDate: string;
  endDate: string;
}

interface FearGreedData {
  date: string;
  fear_greed_index: number;
  price: number;
}

export async function getFearGreedIndex({ startDate, endDate }: FearGreedParams): Promise<FearGreedData[]> {
  const response = await fetch(
    `https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/fear-greed-index?startDate=${startDate}&endDate=${endDate}`
  );

  if (!response.ok) {
    throw new Error('Failed to fetch fear and greed index data');
  }

  return response.json();
}