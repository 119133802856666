import { Label } from '@/components/ui/label';
import { useTheme } from '@/components/theme-provider';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Moon, Sun, Laptop } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

export function ThemeSettings() {
  const { theme, setTheme } = useTheme();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Theme Settings</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="space-y-2">
            <Label>Mode</Label>
            <div className="grid grid-cols-3 gap-2">
              <Button
                variant="outline"
                size="lg"
                onClick={() => setTheme('light')}
                className={cn(
                  'justify-start',
                  theme === 'light' && 'border-2 border-primary'
                )}
              >
                <Sun className="mr-2 h-4 w-4" />
                Light
              </Button>
              <Button
                variant="outline"
                size="lg"
                onClick={() => setTheme('dark')}
                className={cn(
                  'justify-start',
                  theme === 'dark' && 'border-2 border-primary'
                )}
              >
                <Moon className="mr-2 h-4 w-4" />
                Dark
              </Button>
              <Button
                variant="outline"
                size="lg"
                onClick={() => setTheme('system')}
                className={cn(
                  'justify-start',
                  theme === 'system' && 'border-2 border-primary'
                )}
              >
                <Laptop className="mr-2 h-4 w-4" />
                System
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}