import { useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { startOfDay } from 'date-fns';
import { formatDateToYYYYMMDD } from '@/lib/utils/date-format';

const API_BASE = 'https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction';

interface FetchOptions {
  endpoint: string;
  dateRange?: DateRange;
}

async function fetchChartData({ endpoint, dateRange }: FetchOptions) {
  // Add logging
  console.log('Fetching chart data:', {
    endpoint,
    dateRange,
    API_BASE
  });

  // Ensure we have valid Date objects
  const from = dateRange?.from instanceof Date ? startOfDay(dateRange.from) : startOfDay(new Date());
  const to = dateRange?.to instanceof Date ? startOfDay(dateRange.to) : startOfDay(new Date());

  const startDate = formatDateToYYYYMMDD(from);
  const endDate = formatDateToYYYYMMDD(to);

  console.log('Formatted dates:', { startDate, endDate });

  if (!startDate || !endDate) {
    throw new Error('Invalid date range');
  }

  const url = `${API_BASE}/${endpoint}?startDate=${startDate}&endDate=${endDate}`;
  console.log('Making request to:', url);

  const response = await fetch(url);
  
  if (!response.ok) {
    console.error('API error:', {
      status: response.status,
      statusText: response.statusText,
      url
    });
    throw new Error(`API error: ${response.status}`);
  }

  const result = await response.json();
  console.log('Raw API response:', JSON.stringify(result, null, 2));
  return result;
}

export function useChartData(endpoint: string, dateRange?: DateRange) {
  return useQuery({
    queryKey: [endpoint, dateRange?.from, dateRange?.to],
    queryFn: () => fetchChartData({ endpoint, dateRange }),
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    gcTime: 30 * 60 * 1000,   // Keep in cache for 30 minutes
    retry: 2,
    refetchOnWindowFocus: false,
  });
} 