import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Download, CreditCard, CheckCircle2 } from "lucide-react";

const subscriptionPlans = [
  {
    name: "Basic",
    price: "$0",
    description: "Essential Bitcoin analysis tools",
    features: [
      "Basic trend analysis",
      "Limited historical data",
      "Community support",
    ],
    current: false,
  },
  {
    name: "Pro",
    price: "$49",
    period: "month",
    description: "Advanced features for serious analysts",
    features: [
      "Real-time sentiment analysis",
      "Advanced price correlation",
      "Custom alerts",
      "Priority support",
      "API access",
    ],
    current: true,
  },
  {
    name: "Enterprise",
    price: "Custom",
    description: "Custom solutions for organizations",
    features: [
      "Custom integrations",
      "Dedicated support",
      "Training sessions",
      "SLA guarantee",
    ],
    current: false,
  },
];

const billingHistory = [
  {
    id: "INV-001",
    date: "2024-03-01",
    amount: "$49.00",
    status: "Paid",
    description: "Pro Plan - Monthly",
  },
  {
    id: "INV-002",
    date: "2024-02-01",
    amount: "$49.00",
    status: "Paid",
    description: "Pro Plan - Monthly",
  },
  {
    id: "INV-003",
    date: "2024-01-01",
    amount: "$49.00",
    status: "Paid",
    description: "Pro Plan - Monthly",
  },
];

export function BillingSettings() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Billing</h3>
        <p className="text-sm text-muted-foreground">
          Manage your subscription and billing information.
        </p>
      </div>

      {/* Current Plan */}
      <Card>
        <CardHeader>
          <CardTitle>Current Plan</CardTitle>
          <CardDescription>You are currently on the Pro plan.</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <p className="text-2xl font-bold">$49/month</p>
              <p className="text-sm text-muted-foreground">
                Next billing date: April 1, 2024
              </p>
            </div>
            <Button variant="outline">Cancel Subscription</Button>
          </div>
        </CardContent>
      </Card>

      {/* Payment Method */}
      <Card>
        <CardHeader>
          <CardTitle>Payment Method</CardTitle>
          <CardDescription>
            Manage your payment method and billing details.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex items-center gap-4">
            <CreditCard className="h-6 w-6" />
            <div>
              <p className="font-medium">•••• •••• •••• 4242</p>
              <p className="text-sm text-muted-foreground">Expires 12/2024</p>
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Button variant="outline">Update Payment Method</Button>
        </CardFooter>
      </Card>

      {/* Subscription Plans */}
      <div className="grid gap-4 md:grid-cols-3">
        {subscriptionPlans.map((plan) => (
          <Card key={plan.name} className={plan.current ? "border-primary" : ""}>
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                {plan.name}
                {plan.current && (
                  <Badge variant="default">Current Plan</Badge>
                )}
              </CardTitle>
              <CardDescription>
                <span className="text-2xl font-bold">{plan.price}</span>
                {plan.period && <span className="text-sm">/{plan.period}</span>}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="space-y-2 text-sm">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-center gap-2">
                    <CheckCircle2 className="h-4 w-4 text-primary" />
                    {feature}
                  </li>
                ))}
              </ul>
            </CardContent>
            <CardFooter>
              <Button
                variant={plan.current ? "outline" : "default"}
                className="w-full"
                disabled={plan.current}
              >
                {plan.current ? "Current Plan" : "Upgrade"}
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>

      {/* Billing History */}
      <Card>
        <CardHeader>
          <CardTitle>Billing History</CardTitle>
          <CardDescription>View your previous invoices.</CardDescription>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[300px] pr-4">
            <div className="space-y-4">
              {billingHistory.map((invoice) => (
                <div
                  key={invoice.id}
                  className="flex items-center justify-between p-4 border rounded-lg"
                >
                  <div>
                    <p className="font-medium">{invoice.description}</p>
                    <p className="text-sm text-muted-foreground">
                      {invoice.date}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <Badge variant="outline">{invoice.status}</Badge>
                    <p className="font-medium">{invoice.amount}</p>
                    <Button variant="ghost" size="icon">
                      <Download className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  );
}