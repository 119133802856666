import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { format, addDays, startOfToday, startOfDay, subDays } from "date-fns";
import { DateRange } from 'react-day-picker';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatCurrency(value: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
}

export function formatDateForAPI(date: Date | null | undefined): string {
  if (!date) {
    const today = new Date();
    date = subDays(today, 7);
  }
  return format(startOfDay(date), 'yyyy-MM-dd');
}

export function getDefaultDateRange() {
  return {
    from: addDays(startOfToday(), -7),
    to: startOfToday(),
  };
}

export function ensureDateRange(dateRange: DateRange | undefined): { from: Date; to: Date } {
  const today = new Date();
  const defaultEnd = subDays(today, 1);
  const defaultStart = subDays(today, 7);

  if (!dateRange?.from || !dateRange?.to) {
    return {
      from: defaultStart,
      to: defaultEnd,
    };
  }

  return {
    from: dateRange.from,
    to: dateRange.to,
  };
}
