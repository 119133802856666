import { useState } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { format, parseISO } from 'date-fns';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ChartSkeleton } from './chart-skeleton';
import { ChartWatermark } from './chart-watermark';

const mediaTypes = [
  { id: 'financial', name: 'Financial Media', color: '#22c55e' },
  { id: 'crypto', name: 'Crypto Media', color: '#3b82f6' },
  { id: 'social', name: 'Social Media', color: '#f87171' },
  { id: 'communities', name: 'Communities', color: '#a78bfa' },
  { id: 'legacy', name: 'Legacy Media', color: '#fbbf24' },
] as const;

export function MediaBenchmarks() {
  const [selectedTypes, setSelectedTypes] = useState(['financial', 'crypto', 'social']);
  const [isLoading] = useState(false);

  const toggleType = (id: string) => {
    setSelectedTypes(prev =>
      prev.includes(id)
        ? prev.filter(t => t !== id)
        : [...prev, id]
    );
  };

  if (isLoading) {
    return <ChartSkeleton />;
  }

  // Use original mock data
  const data = Array.from({ length: 30 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - (29 - i));
    return {
      date: format(date, 'yyyy-MM-dd'),
      financial: Math.random() * 100,
      crypto: Math.random() * 100,
      social: Math.random() * 100,
      communities: Math.random() * 100,
      legacy: Math.random() * 100,
    };
  });

  return (
    <div className="space-y-4">
      <div className="flex gap-2 flex-wrap">
        {mediaTypes.map(type => (
          <Button
            key={type.id}
            variant={selectedTypes.includes(type.id) ? "default" : "outline"}
            onClick={() => toggleType(type.id)}
            className={cn(
              "transition-all duration-200",
              selectedTypes.includes(type.id) && "border-2"
            )}
            style={{
              borderColor: selectedTypes.includes(type.id) ? type.color : undefined,
              backgroundColor: selectedTypes.includes(type.id) ? `${type.color}20` : undefined,
              color: selectedTypes.includes(type.id) ? type.color : undefined
            }}
          >
            <span
              className="mr-2 h-3 w-3 rounded-full"
              style={{ backgroundColor: type.color }}
            />
            {type.name}
          </Button>
        ))}
      </div>

      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <ChartWatermark />
            <XAxis
              dataKey="date"
              tickFormatter={(value) => format(parseISO(value), 'MMM d')}
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              stroke="#888888"
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${value.toFixed(0)}`}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (!active || !payload?.length) return null;

                return (
                  <Card className={cn(
                    "p-2 shadow-lg border-primary/20",
                    "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
                  )}>
                    <div className="text-sm font-medium">
                      {format(parseISO(label), 'MMMM d, yyyy')}
                    </div>
                    <div className="mt-1 space-y-1">
                      {payload.map((entry: any) => {
                        const type = mediaTypes.find(t => t.id === entry.dataKey);
                        if (!type) return null;

                        return (
                          <div key={entry.dataKey} className="flex items-center gap-2">
                            <div 
                              className="w-2 h-2 rounded-full"
                              style={{ backgroundColor: type.color }}
                            />
                            <span className="text-sm">
                              {type.name}: {entry.value?.toFixed(1)}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                );
              }}
            />
            <Legend />
            {selectedTypes.map(id => {
              const type = mediaTypes.find(t => t.id === id);
              if (!type) return null;

              return (
                <Bar
                  key={type.id}
                  dataKey={type.id}
                  name={type.name}
                  fill={type.color}
                  radius={[4, 4, 0, 0]}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}