import { memo } from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/lib/auth';
import { 
  Home, 
  BarChart2, 
  Table, 
  MessageSquare, 
  DollarSign,
  Building2,
  LifeBuoy,
  Settings,
  ChevronUp,
  ChevronDown,
  LogOut,
  User,
  Newspaper,
} from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useState, useCallback } from 'react';

interface SidebarProps {
  className?: string;
  onNavigate: (view: string) => void;
  currentView?: string;
}

export const Sidebar = memo(function Sidebar({ className, onNavigate, currentView }: SidebarProps) {
  const [isUserMenuExpanded, setIsUserMenuExpanded] = useState(false);
  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const toggleUserMenu = useCallback(() => {
    setIsUserMenuExpanded(prev => !prev);
  }, []);

  const handleSignOut = useCallback(() => {
    signOut();
    navigate('/auth/login');
  }, [signOut, navigate]);

  const mainNavItems = [
    { icon: Home, label: 'Home', value: 'home' },
    { icon: Table, label: 'Feed', value: 'feed' },
    { icon: BarChart2, label: 'Trends', value: 'trends' },
  ];

  const analysisNavItems = [
    { icon: MessageSquare, label: 'Sentiment', value: 'sentiment' },
    { icon: Newspaper, label: 'Media', value: 'media' },
    { icon: DollarSign, label: 'Price', value: 'price', badge: 'PRO' },
    { icon: Building2, label: 'Industry', value: 'industry', badge: 'PRO' },
  ];

  return (
    <div className={cn("flex flex-col h-full bg-background", className)}>
      {/* Logo Header */}
      <div className="p-6 border-b">
        <div className="flex items-center gap-4">
          <img 
            src="https://bitcoinperception.com/content/images/2024/09/New-Perception-Logo--black--1.png" 
            alt="Perception Logo" 
            className="h-10 dark:invert"
            width="40"
            height="40"
            loading="lazy"
          />
          <div>
            <h3 className="font-medium">Perception</h3>
            <p className="text-sm text-muted-foreground">Bitcoin Analysis</p>
          </div>
        </div>
      </div>

      {/* Main Navigation */}
      <ScrollArea className="flex-1 px-4 py-6">
        <div className="space-y-4">
          <div className="px-3 py-2">
            <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
              Overview
            </h2>
            <div className="space-y-1">
              {mainNavItems.map(({ icon: Icon, label, value }) => (
                <Button
                  key={value}
                  variant={currentView === value ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  onClick={() => onNavigate(value)}
                >
                  <Icon className="mr-2 h-4 w-4" />
                  {label}
                </Button>
              ))}
            </div>
          </div>

          <div className="px-3 py-2">
            <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
              Analysis
            </h2>
            <div className="space-y-1">
              {analysisNavItems.map(({ icon: Icon, label, value, badge }) => (
                <Button
                  key={value}
                  variant={currentView === value ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  onClick={() => onNavigate(value)}
                >
                  <Icon className="mr-2 h-4 w-4" />
                  {label}
                  {badge && (
                    <span className="ml-2 px-1.5 py-0.5 rounded text-xs font-medium bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                      {badge}
                    </span>
                  )}
                </Button>
              ))}
            </div>
          </div>

          <div className="px-3 py-2">
            <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
              Support
            </h2>
            <div className="space-y-1">
              <Button
                variant={currentView === 'support' ? "secondary" : "ghost"}
                className="w-full justify-start"
                onClick={() => onNavigate('support')}
              >
                <LifeBuoy className="mr-2 h-4 w-4" />
                Help
              </Button>
              <Button
                variant={currentView === 'settings' ? "secondary" : "ghost"}
                className="w-full justify-start"
                onClick={() => onNavigate('settings')}
              >
                <Settings className="mr-2 h-4 w-4" />
                Settings
              </Button>
            </div>
          </div>
        </div>
      </ScrollArea>

      {/* User Profile Section */}
      <div className="border-t">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Avatar>
                <AvatarImage src={user?.avatar} />
                <AvatarFallback>{user?.name?.[0]?.toUpperCase() || 'U'}</AvatarFallback>
              </Avatar>
              <div>
                <p className="text-sm font-medium">{user?.name || 'User'}</p>
                <p className="text-xs text-muted-foreground">{user?.email}</p>
              </div>
            </div>
            <Button
              variant="ghost"
              size="icon"
              onClick={toggleUserMenu}
            >
              {isUserMenuExpanded ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </Button>
          </div>

          {isUserMenuExpanded && (
            <div className="mt-4 space-y-1">
              <Button
                variant="ghost"
                className="w-full justify-start"
                onClick={() => onNavigate('profile')}
              >
                <User className="mr-2 h-4 w-4" />
                Account
              </Button>
              <Button
                variant="ghost"
                className="w-full justify-start text-red-500 hover:text-red-500 hover:bg-red-500/10"
                onClick={handleSignOut}
              >
                <LogOut className="mr-2 h-4 w-4" />
                Log out
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});