import { toast } from 'sonner';

const API_BASE_URL = 'https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction';

export class ApiError extends Error {
  constructor(message: string, public status?: number, public response?: any) {
    super(message);
    this.name = 'ApiError';
  }
}

interface QueryParams {
  [key: string]: string;
}

export async function fetchFromAPI(
  endpoint: string,
  params: QueryParams
): Promise<Response> {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: String(value)
    }), {})
  );
  const url = `${API_BASE_URL}${endpoint}?${queryParams.toString()}`;

  const response = await fetch(url, {
    headers: {
      'Accept': 'application/json',
    },
  });

  return response;
}

export async function apiRequest<T>(endpoint: string, params: Record<string, any> = {}): Promise<T> {
  try {
    const queryParams = new URLSearchParams(
      Object.entries(params).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: String(value)
      }), {})
    );
    const url = `${API_BASE_URL}${endpoint}?${queryParams.toString()}`;

    const response = await fetch(url, {
      headers: { 'Accept': 'application/json' }
    });

    if (!response.ok) {
      toast.error(`API Error: ${response.statusText}`);
      throw new Error(`API request failed: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    toast.error('Failed to fetch data');
    throw error;
  }
}