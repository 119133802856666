import { useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { ChartWatermark } from './chart-watermark';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { useDailySentiment } from '@/hooks/use-daily-sentiment';
import { ChartSkeleton } from './chart-skeleton';

export function SentimentOverview() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { data, isLoading, error } = useDailySentiment();

  if (isLoading) {
    return <ChartSkeleton className="h-[500px]" />;
  }

  if (error || !data) {
    return (
      <div className="h-[500px] w-full flex items-center justify-center text-muted-foreground">
        Failed to load sentiment data
      </div>
    );
  }

  const safeData = Array.isArray(data) ? data : [];
  const totalCount = safeData.reduce((sum, item) => sum + item.count, 0);
  
  return (
    <div className="h-[500px] w-full relative" id="sentiment-overview">
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center">
          <div className="text-2xl font-bold">{totalCount.toLocaleString()}</div>
          <div className="text-sm text-muted-foreground">Total Mentions</div>
        </div>
      </div>
      <ResponsiveContainer>
        <PieChart>
          <ChartWatermark opacity={0.03} />
          <Pie
            data={safeData}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={120}
            paddingAngle={2}
            dataKey="value"
            onMouseEnter={(_, index) => setActiveIndex(index)}
            onMouseLeave={() => setActiveIndex(null)}
          >
            {safeData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                opacity={activeIndex === null || activeIndex === index ? 1 : 0.6}
                stroke="none"
                className="transition-opacity duration-200"
              />
            ))}
          </Pie>
          <Tooltip
            content={({ active, payload }) => {
              if (!active || !payload?.length) return null;

              const data = payload[0].payload;
              return (
                <Card className={cn(
                  "p-2 shadow-lg border-primary/20",
                  "animate-in fade-in-0 zoom-in-95"
                )}>
                  <div className="flex items-center gap-2">
                    <div 
                      className="w-2 h-2 rounded-full"
                      style={{ backgroundColor: data.color }}
                    />
                    <span className="font-medium">{data.name}</span>
                  </div>
                  <div className="mt-1 font-medium">
                    {data.value}% ({data.count.toLocaleString()} mentions)
                  </div>
                </Card>
              );
            }}
          />
          <Legend 
            verticalAlign="bottom" 
            height={36}
            content={({ payload }) => (
              <div className="flex justify-center gap-6">
                {payload?.map((entry: any, index: number) => (
                  <div 
                    key={`legend-${index}`}
                    className={cn(
                      "flex items-center gap-2 cursor-pointer transition-opacity duration-200",
                      activeIndex === null || activeIndex === index ? "opacity-100" : "opacity-60"
                    )}
                    onMouseEnter={() => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(null)}
                  >
                    <div 
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: entry.color }}
                    />
                    <span className="text-sm font-medium">{entry.value}%</span>
                  </div>
                ))}
              </div>
            )}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}