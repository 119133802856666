import { Card, CardContent } from '@/components/ui/card';
import { Overview } from '../components/overview';
import { MarketMetrics } from '../components/market-metrics';
import { BreakingInsights } from '../components/breaking-insights';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import type { DateRange } from 'react-day-picker';

const MetricsLoadingSkeleton = () => (
  <div className="grid gap-4 md:grid-cols-3">
    {[1, 2, 3].map((i) => (
      <Card key={i} className="relative overflow-hidden">
        <CardContent className="p-6">
          <div className="space-y-2">
            <Skeleton className="h-4 w-[120px]" />
            <Skeleton className="h-7 w-[80px]" />
          </div>
        </CardContent>
      </Card>
    ))}
  </div>
);

interface HomeProps {
  dateRange?: DateRange;
}

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="flex h-[300px] w-full items-center justify-center">
      <p className="text-destructive">Error: {error.message}</p>
    </div>
  );
}

export function Home({ dateRange }: HomeProps) {
  return (
    <div className="space-y-4">
      {/* Market Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<MetricsLoadingSkeleton />}>
            <MarketMetrics />
          </Suspense>
        </ErrorBoundary>
      </div>

      {/* Overview and Breaking Insights */}
      <div className="grid gap-4 md:grid-cols-7">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Card className="md:col-span-4">
            <CardContent className="pl-2 pt-6">
              <Overview dateRange={dateRange} />
            </CardContent>
          </Card>
        </ErrorBoundary>

        <Card className="md:col-span-3">
          <CardContent className="pt-6">
            <BreakingInsights />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}