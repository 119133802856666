import { useQuery } from '@tanstack/react-query';
import { ResponsiveBar } from '@nivo/bar';
import { DateRange } from 'react-day-picker';
import { format } from 'date-fns';
import { Skeleton } from '@/components/ui/skeleton';
import { Alert, AlertDescription } from '@/components/ui/alert';

interface SentimentEvolutionProps {
  dateRange?: DateRange;
}

interface SentimentData {
  date: string;
  positive_percentage: number;
  neutral_percentage: number;
  negative_percentage: number;
}

export function SentimentEvolution({ dateRange }: SentimentEvolutionProps) {
  const { data, isLoading, error } = useQuery<SentimentData[]>({
    queryKey: ['sentiment-evolution', dateRange?.from, dateRange?.to],
    queryFn: async () => {
      try {
        if (!dateRange?.from || !dateRange?.to) return null;
        
        const startDate = format(dateRange.from, 'yyyy-MM-dd');
        const endDate = format(dateRange.to, 'yyyy-MM-dd');
        
        const response = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/daily-sentiment-percentages?startDate=${startDate}&endDate=${endDate}`
        );
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('API Error:', {
            status: response.status,
            text: errorText,
            url: response.url
          });
          throw new Error(`API Error: ${response.status}`);
        }
        
        return await response.json();
      } catch (error) {
        console.error('Fetch error:', error);
        throw error;
      }
    },
    enabled: !!dateRange?.from && !!dateRange?.to,
  });

  if (isLoading) {
    return <Skeleton className="h-[400px]" />;
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>
          {error instanceof Error ? error.message : 'Failed to load sentiment data'}
        </AlertDescription>
      </Alert>
    );
  }

  const chartData = data
    ?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(d => ({
      date: format(new Date(d.date), 'MMM dd'),
      Positive: Math.round(d.positive_percentage),
      Neutral: Math.round(d.neutral_percentage),
      Negative: Math.round(d.negative_percentage),
    })) || [];

  return (
    <div className="h-[400px]">
      <ResponsiveBar
        data={chartData}
        keys={['Positive', 'Neutral', 'Negative']}
        indexBy="date"
        margin={{ top: 30, right: 130, bottom: 90, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear', min: 0, max: 100 }}
        indexScale={{ type: 'band', round: true }}
        colors={[
          '#22c55e', // Positive - green
          'hsl(var(--muted-foreground) / 0.3)', // Neutral - semi-transparent gray
          '#ef4444' // Negative - red
        ]}
        groupMode="stacked"
        enableTotals={false}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: 'hsl(var(--border))',
                strokeWidth: 1,
              },
            },
            ticks: {
              line: {
                stroke: 'hsl(var(--border))',
                strokeWidth: 1,
              },
              text: {
                fill: 'hsl(var(--muted-foreground))',
                fontSize: 11,
              },
            },
            legend: {
              text: {
                fill: 'hsl(var(--muted-foreground))',
                fontSize: 12,
                fontWeight: 500,
              },
            },
          },
          grid: {
            line: {
              stroke: 'hsl(var(--border))',
              strokeWidth: 1,
            },
          },
          legends: {
            text: {
              fill: 'hsl(var(--foreground))',
              fontSize: 12,
              fontWeight: 500,
            },
          },
          tooltip: {
            container: {
              background: 'hsl(var(--background))',
              color: 'hsl(var(--foreground))',
              fontSize: '12px',
              borderRadius: '6px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            },
          },
        }}
        enableLabel={false}
        borderRadius={1}
        borderWidth={0}
        axisBottom={{
          tickSize: 5,
          tickPadding: 12,
          tickRotation: -45,
          legend: 'Date',
          legendPosition: 'middle',
          legendOffset: 80,
          format: (value) => {
            const date = new Date(value);
            const isStartOfWeek = date.getDay() === 0; // Sunday
            return isStartOfWeek ? format(date, 'MMM d') : '';
          }
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Sentiment Distribution %',
          legendPosition: 'middle',
          legendOffset: -40,
          format: (value) => `${value}%`
        }}
        enableGridY={true}
        enableGridX={false}
        gridYValues={5}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 4,
            itemWidth: 100,
            itemHeight: 18,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 10,
            symbolShape: 'circle',
          }
        ]}
        animate={true}
        motionConfig="gentle"
      />
    </div>
  );
} 