import { useQuery } from '@tanstack/react-query';
import type { DateRange } from 'react-day-picker';
import { format, eachDayOfInterval } from 'date-fns';

interface TopicSentimentResponse {
  data: Array<{
    topic: string;
    mention_volume: number;
    day: string;
  }>;
}

function createRandomDistribution(total: number, days: number): number[] {
  // Create a random distribution that sums to total
  const distribution = Array(days).fill(0).map(() => Math.random());
  const sum = distribution.reduce((a, b) => a + b, 0);
  return distribution.map(v => Math.round((v / sum) * total));
}

export function useTopicSentiment(dateRange?: DateRange) {
  return useQuery<TopicSentimentResponse, Error>({
    queryKey: ['topic-sentiment', dateRange?.from, dateRange?.to],
    queryFn: async () => {
      if (!dateRange?.from || !dateRange?.to) {
        return { data: [] };
      }
      
      const startDate = format(dateRange.from, 'yyyy-MM-dd');
      const endDate = format(dateRange.to, 'yyyy-MM-dd');
      
      const response = await fetch(
        `https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/topic-sentiment?startDate=${startDate}&endDate=${endDate}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch topic sentiment data');
      }
      
      const rawData = await response.json() as Array<{
        topic: string;
        mention_volume: number;
        positive_percentage: number;
        neutral_percentage: number;
        negative_percentage: number;
      }>;
      const days = eachDayOfInterval({
        start: dateRange.from,
        end: dateRange.to
      });

      // Create distributions for each topic
      const topicDistributions = rawData.reduce((acc: Record<string, any>, item: any) => {
        if (!acc[item.topic]) {
          acc[item.topic] = {
            volumes: createRandomDistribution(item.mention_volume, days.length),
            positive_percentage: item.positive_percentage,
            neutral_percentage: item.neutral_percentage,
            negative_percentage: item.negative_percentage,
          };
        }
        return acc;
      }, {});

      // Create daily data points
      const dataWithDates = days.flatMap((day, dayIndex) => {
        return Object.entries(topicDistributions).map(([topic, data]) => ({
          topic,
          day: format(day, 'yyyy-MM-dd'),
          mention_volume: data.volumes[dayIndex],
          positive_percentage: data.positive_percentage,
          neutral_percentage: data.neutral_percentage,
          negative_percentage: data.negative_percentage,
        }));
      });

      return { data: dataWithDates };
    },
    enabled: !!dateRange?.from && !!dateRange?.to,
  });
}
