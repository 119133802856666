import { useState, useCallback, useEffect, Suspense } from 'react';
import { Sidebar } from './sidebar';
import { MainNav } from './main-nav';
import { DatePickerWithRange } from './date-range';
import { DateRange } from 'react-day-picker';
import { subDays } from 'date-fns';
import { ChannelVolumeProvider } from '@/hooks/use-channel-volume-context';
import { LoadingScreen } from '../loading-screen';
import { Home } from './pages/home';
import { Feed } from './pages/feed';
import { Trends } from './pages/trends';
import { Topics } from './pages/topics';
import { Sentiment } from './pages/sentiment';
import { PricePage } from "./pages/price";
import { Industry } from './pages/industry';
import { Media } from './pages/media';
import { Support } from './pages/support';
import { Settings } from './pages/settings';
import { TooltipProvider } from '@/components/ui/tooltip';
import { useDateRange } from '@/hooks/use-date-range';

type View = 'home' | 'feed' | 'trends' | 'topics' | 'sentiment' | 'price' | 'industry' | 'media' | 'support' | 'settings';

const VIEWS_WITHOUT_DATE_PICKER: View[] = ['feed'];

export function Dashboard() {
  const [view, setView] = useState<View>('home');
  const [previousView, setPreviousView] = useState<View>('home');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  const { dateRange, setDateRange } = useDateRange();

  useEffect(() => {
    let transitionTimer: ReturnType<typeof setTimeout>;
    let loadingTimer: ReturnType<typeof setTimeout>;

    if (isTransitioning) {
      transitionTimer = setTimeout(() => {
        setIsTransitioning(false);
      }, 300); // Adjust timing as needed
    }

    if (isLoading) {
      loadingTimer = setTimeout(() => {
        setIsLoading(false);
      }, 350); // Slightly longer than transition
    }

    return () => {
      if (transitionTimer) clearTimeout(transitionTimer);
      if (loadingTimer) clearTimeout(loadingTimer);
    };
  }, [isTransitioning, isLoading]);

  const handleNavigate = useCallback((newView: View) => {
    if (newView === view) return;
    
    setIsLoading(true);
    setPreviousView(view);
    
    requestAnimationFrame(() => {
      setIsTransitioning(true);
      
      setTimeout(() => {
        setView(newView);
        
        requestAnimationFrame(() => {
          setIsTransitioning(false);
          setIsLoading(false);
        });
      }, 50);
    });

    setSidebarOpen(false);
  }, [view]);

  const handleSidebarOpenChange = useCallback((open: boolean) => {
    setSidebarOpen(open);
  }, []);

  const handleDateRangeChange = useCallback((range: DateRange | undefined) => {
    if (!range) {
      const today = new Date();
      today.setHours(23, 59, 59, 999);  // Set to end of day
      setDateRange({ 
        from: subDays(today, 6), 
        to: today 
      });
      return;
    }

    const to = new Date(range.to || new Date());
    to.setHours(23, 59, 59, 999);  // Set to end of day
    
    setDateRange({ 
      from: range.from, 
      to 
    });
  }, [setDateRange]);

  const renderView = () => {
    const props = { dateRange };

    const getViewComponent = (v: View) => {
      switch (v) {
        case 'home':
          return <Home dateRange={dateRange} />;
        case 'feed':
          return <Feed />;
        case 'trends':
          return <Trends dateRange={dateRange} />;
        case 'topics':
          return <Topics {...props} />;
        case 'sentiment':
          return <Sentiment {...props} />;
        case 'price':
          return <PricePage {...props} />;
        case 'industry':
          return <Industry {...props} />;
        case 'media':
          return <Media />;
        case 'support':
          return <Support />;
        case 'settings':
          return <Settings />;
        default:
          return <Home dateRange={dateRange} />;
      }
    };

    return (
      <div className="view-container">
        {isTransitioning && (
          <div 
            className="view-content exit"
            key={`${previousView}-exit`}
          >
            {getViewComponent(previousView)}
          </div>
        )}
        <div 
          className={`view-content ${!isTransitioning ? 'active' : ''} ${isLoading ? 'loading' : ''}`}
          key={`${view}-active`}
        >
          {getViewComponent(view)}
        </div>
      </div>
    );
  };

  return (
    <TooltipProvider>
      <ChannelVolumeProvider dateRange={dateRange}>
        <div className="layout-root">
          <Sidebar 
            className="layout-sidebar hidden lg:block" 
            onNavigate={(view: string) => handleNavigate(view as View)}
            currentView={view}
          />
          <div className="layout-main">
            <header className="layout-header">
              <div className="flex h-16 items-center px-4">
                <MainNav 
                  className="lg:hidden" 
                  onNavigate={(view: string) => handleNavigate(view as View)}
                  isOpen={sidebarOpen}
                  onOpenChange={handleSidebarOpenChange}
                  currentView={view}
                />
                
                <div className="lg:hidden ml-4">
                  <img 
                    src="https://bitcoinperception.com/content/images/2024/09/New-Perception-Logo--black--1.png" 
                    alt="Perception Logo" 
                    className="h-6 dark:invert"
                    loading="lazy"
                    width="120"
                    height="24"
                  />
                </div>

                <div className="ml-auto flex items-center space-x-4">
                  <div className="hidden sm:flex items-center space-x-4">
                    {!VIEWS_WITHOUT_DATE_PICKER.includes(view) && (
                      <DatePickerWithRange 
                        date={dateRange}
                        onDateChange={handleDateRangeChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </header>
            <main className="layout-content">
              <div className="layout-container">
                <Suspense fallback={<LoadingScreen />}>
                  {renderView()}
                </Suspense>
              </div>
            </main>
          </div>
        </div>
      </ChannelVolumeProvider>
    </TooltipProvider>
  );
}