import { DateRange } from 'react-day-picker';
import { format, endOfDay, parseISO, startOfDay } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import type { VolumeDataPoint } from '@/types/volume';

interface UseVolumeDataOptions {
  period?: string;
  dateRange?: DateRange;
}

const defaultDateRange: DateRange = {
  from: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
  to: new Date(),
};

async function fetchVolumeData(params: Record<string, any> = {}): Promise<VolumeDataPoint[]> {
  const url = new URL('https://btcpapifunction-45998414364.us-central1.run.app/btcpapifunction/daily-count');
  
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      url.searchParams.append(key, String(value));
    }
  });

  const response = await fetch(url.toString(), {
    headers: {
      'Accept': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch volume data');
  }

  const data = await response.json();

  if (!Array.isArray(data)) {
    throw new Error('Invalid volume data format');
  }

  // Get date range
  const startDate = params.startDate ? parseISO(params.startDate) : defaultDateRange.from;
  const endDate = params.endDate ? parseISO(params.endDate) : startOfDay(new Date());

  // Create a map of all dates in range
  const dateMap = new Map<string, number>();
  let currentDate = startDate ? new Date(startDate.getTime()) : new Date(defaultDateRange.from || Date.now());

  // Fill dates up to today (not including tomorrow)
  while (currentDate <= endDate) {
    dateMap.set(format(currentDate, 'yyyy-MM-dd'), 0);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Fill in actual data
  data.forEach(item => {
    const date = format(parseISO(item.date), 'yyyy-MM-dd');
    if (dateMap.has(date)) {
      dateMap.set(date, Math.max(0, Number(item.total_entries)));
    }
  });

  // Convert map to array and sort by date
  const processedData = Array.from(dateMap.entries()).map(([date, volume]) => ({
    date,
    volume
  })).sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());

  return processedData;
}

export function useVolumeData({ period, dateRange = defaultDateRange }: UseVolumeDataOptions = {}) {
  const params: Record<string, any> = {};
  
  if (dateRange?.from && dateRange?.to) {
    params.startDate = format(dateRange.from, 'yyyy-MM-dd');
    params.endDate = format(endOfDay(dateRange.to), 'yyyy-MM-dd');
  } else if (period) {
    params.period = period;
  } else {
    params.startDate = format(defaultDateRange.from || new Date(Date.now() - 6 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
    params.endDate = format(endOfDay(defaultDateRange.to || new Date()), 'yyyy-MM-dd');
  }

  return useQuery<VolumeDataPoint[], Error>({
    queryKey: ['volume-data', params],
    queryFn: () => fetchVolumeData(params),
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000,   // 30 minutes (renamed from cacheTime)
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
  });
}