import { memo } from 'react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from '@/components/ui/sheet';
import { Menu } from 'lucide-react';
import { Sidebar } from './sidebar';

interface MainNavProps {
  className?: string;
  onNavigate: (view: string) => void;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  currentView?: string;
}

export const MainNav = memo(function MainNav({ 
  className, 
  onNavigate, 
  isOpen, 
  onOpenChange,
  currentView 
}: MainNavProps) {
  return (
    <div className={className}>
      <Sheet open={isOpen} onOpenChange={onOpenChange}>
        <SheetTrigger asChild>
          <Button variant="ghost" size="icon">
            <Menu className="h-6 w-6" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="p-0 w-64">
          <Sidebar 
            onNavigate={onNavigate} 
            className="border-none" 
            currentView={currentView}
          />
        </SheetContent>
      </Sheet>
    </div>
  );
});