const CRYPTOCOMPARE_API_KEY = import.meta.env.VITE_CRYPTOCOMPARE_API_KEY || '';

export async function getBitcoinPrice(): Promise<number> {
  const response = await fetch(
    `https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD`,
    {
      headers: {
        'authorization': `Apikey ${CRYPTOCOMPARE_API_KEY}`
      }
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch Bitcoin price');
  }

  const data = await response.json();
  return data.USD;
} 