import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import html2canvas from 'html2canvas';
import { useState } from 'react';
import { toast } from 'sonner';

interface ShareButtonProps {
  chartId: string;
  title: string;
  metrics?: Array<{ label: string; value: string }>;
}

const XLogo = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" aria-hidden="true">
    <path
      fill="currentColor"
      d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
    />
  </svg>
);

export function ShareButton({ chartId, title, metrics }: ShareButtonProps) {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const captureChart = async () => {
    setIsLoading(true);
    try {
      const element = document.getElementById(chartId);
      console.log('Attempting to capture chart with ID:', chartId);
      console.log('Found element:', element);
      
      if (!element) {
        throw new Error(`Chart element not found with ID: ${chartId}`);
      }

      const wrapper = document.createElement('div');
      wrapper.style.backgroundColor = '#000';
      wrapper.style.padding = '24px';
      wrapper.style.borderRadius = '8px';
      wrapper.style.width = '800px';
      wrapper.style.display = 'flex';
      wrapper.style.flexDirection = 'column';
      wrapper.style.gap = '16px';

      // Simple title bar
      const titleDiv = document.createElement('div');
      titleDiv.style.display = 'flex';
      titleDiv.style.alignItems = 'center';
      titleDiv.style.gap = '8px';
      
      const bar = document.createElement('div');
      bar.style.width = '4px';
      bar.style.height = '16px';
      bar.style.background = 'linear-gradient(to bottom, #22c55e, #3b82f6)';
      bar.style.borderRadius = '9999px';
      titleDiv.appendChild(bar);

      const titleText = document.createElement('h3');
      titleText.style.color = '#fff';
      titleText.style.fontSize = '18px';
      titleText.style.fontWeight = '600';
      titleText.textContent = title;
      titleDiv.appendChild(titleText);

      wrapper.appendChild(titleDiv);
      
      // Chart Clone
      const clone = element.cloneNode(true) as HTMLElement;
      clone.style.width = '100%';
      clone.style.height = 'auto';
      clone.style.marginTop = '8px';
      clone.style.transform = 'scale(1.2)';
      clone.style.transformOrigin = 'left center';
      
      const originalTitle = clone.querySelector('.flex.items-center.justify-between');
      if (originalTitle) {
        originalTitle.remove();
      }

      const svgElements = clone.getElementsByTagName('svg');
      Array.from(svgElements).forEach(svg => {
        svg.style.width = '100%';
        svg.style.height = 'auto';
      });

      const containers = clone.getElementsByClassName('recharts-wrapper');
      Array.from(containers).forEach(container => {
        (container as HTMLElement).style.width = '100%';
      });

      wrapper.appendChild(clone);

      // Mentions Count
      const mentionsCount = document.querySelector('.text-center.text-sm.text-muted-foreground');
      if (mentionsCount) {
        const mentionsText = document.createElement('p');
        mentionsText.style.color = '#6b7280';
        mentionsText.style.fontSize = '14px';
        mentionsText.style.textAlign = 'center';
        mentionsText.style.marginTop = '16px';
        mentionsText.textContent = mentionsCount.textContent || '';
        wrapper.appendChild(mentionsText);
      }

      document.body.appendChild(wrapper);

      const canvas = await html2canvas(wrapper, {
        backgroundColor: '#000',
        scale: 2,
        useCORS: true,
        allowTaint: true,
        width: 800,
        height: wrapper.offsetHeight,
      });

      document.body.removeChild(wrapper);

      const url = canvas.toDataURL('image/png');
      setImageUrl(url);
    } catch (err) {
      const error = err as Error;
      console.error('Failed to capture chart:', error);
      toast.error('Failed to capture chart');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog 
      open={isOpen} 
      onOpenChange={(open) => {
        setIsOpen(open);
        if (open) {
          captureChart();
        } else {
          setImageUrl('');
        }
      }}
    >
      <DialogTrigger asChild>
        <Button 
          variant="outline" 
          size="sm"
          className="gap-2"
        >
          <XLogo />
          Share
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Share {title}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          {isLoading ? (
            <div className="h-48 flex items-center justify-center">
              <span className="text-muted-foreground animate-pulse">
                Capturing chart...
              </span>
            </div>
          ) : imageUrl ? (
            <div className="border rounded-lg overflow-hidden bg-black">
              <img 
                src={imageUrl} 
                alt={title}
                className="w-full h-auto object-contain"
                style={{ 
                  maxHeight: '500px',
                  width: '100%'
                }}
              />
            </div>
          ) : null}
          
          {metrics && (
            <div className="grid gap-2">
              {metrics.map((metric, index) => (
                <div key={index} className="flex justify-between text-sm">
                  <span className="text-muted-foreground">{metric.label}</span>
                  <span className="font-medium">{metric.value}</span>
                </div>
              ))}
            </div>
          )}

          <div className="flex gap-2">
            <Button 
              className="flex-1"
              disabled={!imageUrl || isLoading}
              onClick={() => {
                if (imageUrl) {
                  const link = document.createElement('a');
                  link.href = imageUrl;
                  link.download = `${title.toLowerCase().replace(/\s+/g, '-')}.png`;
                  link.click();
                  toast.success('Chart downloaded');
                }
              }}
            >
              Download Image
            </Button>
            <Button 
              variant="outline" 
              className="flex-1 gap-2"
              disabled={!imageUrl || isLoading}
              onClick={() => {
                const text = `Check out this ${title} from Perception`;
                const url = window.location.href;
                window.open(
                  `https://x.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`,
                  '_blank'
                );
              }}
            >
              <XLogo />
              Share on X
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}