import { useChartData } from '@/hooks/use-chart-data';
import { Progress } from '@/components/ui/progress';
import { ShareButton } from './share-button';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { useMemo } from 'react';
import { Skeleton } from '@/components/ui/skeleton';

interface ChannelVolumeProps {
  dateRange?: DateRange;
}

interface Channel {
  name: string;
  volume: number;
  change: number;
  color: string;
  entries: number;
}

export function ChannelVolume({ dateRange }: ChannelVolumeProps) {
  const { data, isLoading, error } = useChartData('channel-volume-with-change', dateRange);

  console.log('Channel Volume Data:', {
    data,
    isLoading,
    error,
    dateRange
  });

  const transformedData = useMemo<Channel[] | null>(() => {
    if (!data) {
      console.log('No data received from API');
      return null;
    }
    
    return data.map((item: any) => ({
      name: item.channel_category,
      volume: Number(item.percentage) || 0,
      change: Number(item.percentage_change) || 0,
      entries: Number(item.total_entries) || 0,
      color: getChannelColor(item.channel_category)
    }));
  }, [data]);

  function getChannelColor(category: string): string {
    const colorMap: Record<string, string> = {
      'Social Media': 'bg-blue-500',
      'Communities': 'bg-green-500',
      'Crypto Media': 'bg-purple-500',
      'Financial Media': 'bg-orange-500',
      'Legacy Media': 'bg-yellow-500',
      'Bitcoin Repositories': 'bg-red-500',
      'Unknown': 'bg-gray-500'
    };

    return colorMap[category] || 'bg-primary';
  }

  if (isLoading) {
    console.log('Channel Volume is loading...');
    return <Skeleton className="h-[200px] w-full" />;
  }
  
  if (error) {
    console.error('Channel Volume error:', error);
    throw error;
  }

  if (!transformedData) {
    console.log('No transformed data available');
    return null;
  }

  const metrics = transformedData.map((channel: Channel) => ({
    label: channel.name,
    value: `${channel.volume}% (${channel.change > 0 ? '+' : ''}${channel.change}%)`,
  }));

  const dateText = dateRange?.from && dateRange?.to
    ? `Selected dates: ${format(dateRange.from, 'MM/dd/yyyy')} - ${format(dateRange.to, 'MM/dd/yyyy')}`
    : 'Selected dates: Last 7 days';

  return (
    <div className="space-y-6" id="channel-volume">
      <div className="flex justify-between items-center">
        <div>
          <p className="text-sm text-muted-foreground">{dateText}</p>
        </div>
        <ShareButton 
          chartId="channel-volume"
          title="Channel Volume Distribution"
          metrics={metrics}
        />
      </div>

      {transformedData?.map((channel: Channel, index: number) => (
        <div key={`${channel.name}-${index}`} className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="font-medium">{channel.name}</span>
            <div className="flex items-center gap-2">
              <span>{channel.volume.toFixed(1)}%</span>
              <span className={channel.change >= 0 ? 'text-green-500' : 'text-red-500'}>
                ({channel.change > 0 ? '+' : ''}{channel.change.toFixed(1)}%)
              </span>
            </div>
          </div>
          <Progress 
            value={channel.volume} 
            className="h-2 bg-primary/10"
            indicatorClassName={channel.color}
          />
        </div>
      ))}
    </div>
  );
}