import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { fetchFeed } from '@/lib/api/feed';

const RETRY_COUNT = 3;
const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export function useFeed(
  startDate?: Date, 
  endDate?: Date, 
  outlet?: string, 
  sentiment?: string,
  limit?: number
) {
  // Format dates to match API's expected format
  const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : undefined;
  const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : undefined;

  return useQuery({
    queryKey: ['feed', formattedStartDate, formattedEndDate, outlet, sentiment, limit],
    queryFn: () => fetchFeed({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      outlet,
      sentiment,
      limit
    }),
    staleTime: CACHE_TIME,
    gcTime: CACHE_TIME * 2,
    retry: RETRY_COUNT,
    retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: 60000,
  });
}