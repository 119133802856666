import React from 'react';
import { CalendarIcon } from 'lucide-react';
import { format, isValid, subDays } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface DatePickerWithRangeProps {
  className?: string;
  date?: DateRange;
  onDateChange?: (date: DateRange | undefined) => void;
}

const presets = [
  { label: '7D', value: '7d', days: 7 },
  { label: '30D', value: '30d', days: 30 },
  { label: '90D', value: '90d', days: 90 },
  { label: '1Y', value: 'year', days: 365 },
] as const;

function formatDateRange(date: DateRange | undefined): string {
  if (!date?.from) {
    // Default to last 7 days
    const to = new Date();
    const from = subDays(to, 6);
    return `${format(from, 'LLL dd')} - ${format(to, 'LLL dd, y')}`;
  }
  
  try {
    if (!isValid(date.from)) {
      const to = new Date();
      const from = subDays(to, 6);
      return `${format(from, 'LLL dd')} - ${format(to, 'LLL dd, y')}`;
    }
    
    const fromStr = format(date.from, 'LLL dd');
    if (!date.to) return fromStr;
    
    if (!isValid(date.to)) return fromStr;
    return `${fromStr} - ${format(date.to, 'LLL dd, y')}`;
  } catch (error) {
    console.error('Date range formatting error:', error);
    const to = new Date();
    const from = subDays(to, 6);
    return `${format(from, 'LLL dd')} - ${format(to, 'LLL dd, y')}`;
  }
}

export function DatePickerWithRange({
  className,
  date,
  onDateChange,
}: DatePickerWithRangeProps) {
  const handlePresetChange = (value: string) => {
    const preset = presets.find(p => p.value === value);
    if (!preset) return;

    const to = new Date();
    const from = subDays(to, preset.days - 1); // Subtract days - 1 to include today

    onDateChange?.({ from, to });
  };

  // Set default to 7D on mount
  React.useEffect(() => {
    if (!date?.from && !date?.to) {
      handlePresetChange('7d');
    }
  }, [date]);

  return (
    <div className="flex items-center gap-2">
      <Select defaultValue="7d" onValueChange={handlePresetChange}>
        <SelectTrigger className={`w-[100px] ${className}`}>
          <SelectValue placeholder="Preset" />
        </SelectTrigger>
        <SelectContent>
          {presets.map((preset) => (
            <SelectItem key={preset.value} value={preset.value}>
              {preset.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {formatDateRange(date)}
          </Button>
        </PopoverTrigger>
        <PopoverContent 
          className="w-auto p-0" 
          align="start"
        >
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={onDateChange}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}