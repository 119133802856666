import { useTopicSentiment } from '@/hooks/use-topic-sentiment';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Skeleton } from '@/components/ui/skeleton';
import { ResponsiveLine } from '@nivo/line';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useState, useMemo } from 'react';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { X } from 'lucide-react';
import { ShareButton } from './share-button';
import { DateRange } from 'react-day-picker';

// Add a function to get a consistent color for each topic
const getTopicColor = (index: number) => {
  const colors = ['#22c55e', '#3b82f6', '#f97316', '#8b5cf6', '#ec4899', '#06b6d4'];
  return colors[index % colors.length];
};

interface TopicTrendsProps {
  dateRange?: DateRange;
}

export function TopicTrends({ dateRange }: TopicTrendsProps) {
  const { data, isLoading, error } = useTopicSentiment(dateRange);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  // Get unique topics from data
  const availableTopics = useMemo(() => {
    if (!data?.data) return [];
    return Array.from(new Set(data.data.map(item => item.topic)))
      .filter(topic => topic && topic.trim() !== '')
      .sort();
  }, [data]);

  // Transform data for line chart
  const chartData = useMemo(() => {
    if (!data?.data || !selectedTopics.length) return [];

    // Get all unique days and sort them chronologically
    const days = Array.from(new Set(data.data.map(item => item.day)))
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    console.log('Available days:', days);

    // Create series for each selected topic
    return selectedTopics.map(topic => {
      // Get all data points for this topic
      const topicData = data.data.filter(item => item.topic === topic);
      console.log(`Data for topic ${topic}:`, topicData);

      // Create a data point for each day
      return {
        id: topic,
        data: days.map(day => {
          const dayData = topicData.find(item => item.day === day);
          return {
            x: day,
            y: dayData?.mention_volume || 0
          };
        })
      };
    });
  }, [data, selectedTopics]);

  // Update the handleTopicSelect function
  const handleTopicSelect = (topic: string) => {
    if (topic && !selectedTopics.includes(topic)) {
      setSelectedTopics(prev => [...prev, topic]);
    }
  };

  // Remove a topic from selection
  const handleTopicRemove = (topic: string) => {
    setSelectedTopics(selectedTopics.filter(t => t !== topic));
  };

  // Add metrics for the share button
  const metrics = useMemo(() => {
    if (!data?.data || !selectedTopics.length) return [];

    return selectedTopics.map(topic => {
      const topicData = data.data.filter(d => d.topic === topic);
      const totalVolume = topicData.reduce((sum, d) => sum + d.mention_volume, 0);
      const avgVolume = Math.round(totalVolume / topicData.length);

      return {
        label: topic,
        value: `${avgVolume.toLocaleString()} avg. mentions`
      };
    });
  }, [data, selectedTopics]);

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>
          {error instanceof Error ? error.message : 'Failed to load topic trends data'}
        </AlertDescription>
      </Alert>
    );
  }

  if (isLoading) {
    return <Skeleton className="w-full h-[400px]" />;
  }

  if (!data?.data || data.data.length === 0) {
    return (
      <Alert>
        <AlertDescription>
          No topic data available for the selected date range.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Card className="p-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            <div className="h-4 w-1 bg-gradient-to-b from-blue-500 to-green-500 rounded-full" />
            <h3 className="text-lg font-medium">Topic Evolution</h3>
          </div>
          <p className="text-sm text-muted-foreground">
            Track how topics emerge, peak, and fade over time
          </p>
        </div>
        <ShareButton 
          chartId="topic-trends"
          title="Topic Evolution"
          metrics={metrics}
        />
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Select onValueChange={handleTopicSelect}>
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder="Select topics..." />
            </SelectTrigger>
            <SelectContent>
              {availableTopics.map(topic => (
                <SelectItem
                  key={topic}
                  value={topic}
                  disabled={selectedTopics.includes(topic)}
                >
                  {topic}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <div className="flex flex-wrap gap-2">
            {selectedTopics.map((topic, index) => (
              <Badge 
                key={topic} 
                variant="secondary"
                className="flex items-center gap-2 hover:bg-secondary/80 px-3 py-1"
              >
                <div 
                  className="w-2 h-2 rounded-full" 
                  style={{ backgroundColor: getTopicColor(index) }}
                />
                {topic}
                <X 
                  className="h-3 w-3 cursor-pointer hover:text-destructive" 
                  onClick={() => handleTopicRemove(topic)}
                />
              </Badge>
            ))}
          </div>
        </div>
      </div>

      <div className="h-[400px]">
        {selectedTopics.length > 0 ? (
          <ResponsiveLine
            data={chartData.map((series, index) => ({
              ...series,
              color: getTopicColor(index) // Add color to each series
            }))}
            margin={{ top: 20, right: 120, bottom: 50, left: 60 }}
            xScale={{
              type: 'point'
            }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: 'Date',
              legendOffset: 36,
              legendPosition: 'middle'
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Volume',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            enableGridX={false}
            gridYValues={5}
            enablePoints={false}
            enableSlices="x"
            curve="monotoneX"
            enableArea={true}
            areaOpacity={0.1}
            useMesh={true}
            animate={true}
            motionConfig="gentle"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 11,
                  },
                },
                legend: {
                  text: {
                    fontSize: 12,
                    fontWeight: 500,
                  },
                },
              },
              grid: {
                line: {
                  stroke: 'hsl(var(--border))',
                  strokeWidth: 1,
                },
              },
              tooltip: {
                container: {
                  background: 'hsl(var(--background))',
                  color: 'hsl(var(--foreground))',
                  fontSize: '12px',
                },
              },
            }}
            colors={{ datum: 'color' }} // Use the color from the data
            legends={[]} // Remove legends completely
          />
        ) : (
          <div className="h-full flex items-center justify-center text-muted-foreground">
            Select topics to visualize their volume over time
          </div>
        )}
      </div>
    </Card>
  );
}