import { useState, useCallback, useEffect, useMemo } from 'react';
import { Card, CardContent, CardTitle, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { RefreshCw, Search } from 'lucide-react';
import { useFeed } from '@/hooks/use-feed';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { FeedTable } from '../components/feed-table';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import type { FeedEntry } from '@/lib/api/feed';  // Import the FeedEntry type
import { useDebounce } from '@/hooks/use-debounce';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ErrorBoundary } from '@/components/error-boundary';
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

// Define sentiment options constant
const SENTIMENT_OPTIONS = [
  { value: 'all', label: 'All Sentiments' },
  { value: 'Positive', label: 'Positive' },
  { value: 'Neutral', label: 'Neutral' },
  { value: 'Negative', label: 'Negative' },
] as const;

type SentimentType = 'all' | FeedEntry['Sentiment'];

// Define source categories as a const array
const SOURCE_CATEGORIES = [
  'All Sources',
  'Research',
  'Tech Media',
  'Communities',
  'Crypto Media',
  'Legacy Media',
  'Social Media',
  'Financial Media',
  'Bitcoin Repositories'
] as const;

// Define the mapping of outlets to source categories
const OUTLET_TO_SOURCE_MAP: Record<string, string> = {
  // Research
  'K33 Research': 'Research',
  'Galaxy Insights': 'Research',
  'NYDIG': 'Research',

  // Tech Media
  'Engadget': 'Tech Media',
  'Gizmodo': 'Tech Media',
  'TechCrunch': 'Tech Media',
  'The Verge': 'Tech Media',
  'Wired': 'Tech Media',
  'Mashable': 'Tech Media',
  'Ars Technica': 'Tech Media',

  // Communities
  'Stacker News': 'Communities',
  'Reddit': 'Communities',
  'Hacker News': 'Communities',

  // Crypto Media
  'CoinDesk': 'Crypto Media',
  'Blockworks': 'Crypto Media',
  'The Block': 'Crypto Media',
  'CryptoSlate': 'Crypto Media',
  'Crypto News': 'Crypto Media',
  'BeInCrypto': 'Crypto Media',
  'Cointelegraph': 'Crypto Media',
  'The Defiant': 'Crypto Media',
  'DeCrypt': 'Crypto Media',
  'Decrypt': 'Crypto Media',

  // Legacy Media
  'Le Monde': 'Legacy Media',
  'Rolling Stone': 'Legacy Media',
  'National Post': 'Legacy Media',
  'The Economist': 'Legacy Media',
  'Clarín': 'Legacy Media',
  'Reforma': 'Legacy Media',
  'The New Yorker': 'Legacy Media',
  'Todo Noticias': 'Legacy Media',
  'Los Angeles Times': 'Legacy Media',
  'CNN': 'Legacy Media',
  'The New York Times': 'Legacy Media',
  'The Boston Globe': 'Legacy Media',
  'Telecinco': 'Legacy Media',
  'La Sexta': 'Legacy Media',
  'El Pas': 'Legacy Media',
  'NBC': 'Legacy Media',
  'Crónica': 'Legacy Media',
  'ABC': 'Legacy Media',
  'El País': 'Legacy Media',
  'La Nación': 'Legacy Media',
  'El Periódico': 'Legacy Media',
  'La Nacin': 'Legacy Media',
  'AP': 'Legacy Media',
  'laSexta': 'Legacy Media',

  // Social Media
  'X': 'Social Media',

  // Financial Media
  'Forbes': 'Financial Media',
  'Bloomberg': 'Financial Media',
  'Fortune': 'Financial Media',
  'CNBC': 'Financial Media',
  'Financial Times': 'Financial Media',
  'Wall Street Journal': 'Financial Media',
  "Barron's": 'Financial Media',
  'Reuters': 'Financial Media',
  'Yahoo Finance': 'Financial Media',
  'Fox Business': 'Financial Media',

  // Bitcoin Repositories
  'fedimint/fedimint': 'Bitcoin Repositories',
  'lightningnetwork/lnd': 'Bitcoin Repositories',
  'rsksmart/rskj': 'Bitcoin Repositories',
  'bitcoin/bitcoin': 'Bitcoin Repositories',
  'ElementsProject/elements': 'Bitcoin Repositories',
  'ElementsProject/lightning': 'Bitcoin Repositories',
  'btcpayserver/btcpayserver': 'Bitcoin Repositories',
  'ACINQ/eclair': 'Bitcoin Repositories',
} as const;

// Add a helper function to normalize outlet names
function normalizeOutletName(outlet: string): string {
  // Handle case variations and common misspellings
  const normalized = outlet.trim().toLowerCase();
  if (normalized === 'lasexta' || normalized === 'la sexta') return 'La Sexta';
  if (normalized === 'la nacin') return 'La Nación';
  // Add more normalizations as needed
  return outlet;
}

// Helper function to get the source category for an item
function getSourceCategory(item: FeedEntry): string {
  // First check if it's from X/Twitter
  if (item.From?.startsWith('@')) {
    return 'Social Media';
  }

  // Otherwise use the outlet mapping
  const normalizedOutlet = normalizeOutletName(item.Outlet);
  return OUTLET_TO_SOURCE_MAP[normalizedOutlet] || 'Other';
}

// Add this interface for the logos
interface NewsSource {
  name: string;
  logo: string;
  alt: string;
}

// Add this constant for the news sources
const NEWS_SOURCES: NewsSource[] = [
  {
    name: 'Bloomberg',
    logo: '/logos/Bloomberg.svg',  // Add these images to your public folder
    alt: 'Bloomberg Logo'
  },
  {
    name: 'X',
    logo: '/logos/X.svg',
    alt: 'X Logo'
  },
  {
    name: 'CoinDesk',
    logo: '/logos/CoinDesk.svg',
    alt: 'CoinDesk Logo'
  },
  {
    name: 'MSNBC',
    logo: '/logos/MSNBC_Logo.svg',
    alt: 'MSNBC Logo'
  },
  {
    name: 'GitHub',
    logo: '/logos/Github.svg',
    alt: 'GitHub Logo'
  },
  {
    name: 'Reddit',
    logo: '/logos/Reddit.svg',
    alt: 'Reddit Logo'
  }
];

function FeedHeader() {
  return (
    <Card className="mb-6">
      <CardHeader className="text-center">
        <p className="text-muted-foreground mt-2">
          Real-time aggregated feed providing updates and market insights from top publications and sources
        </p>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
          {NEWS_SOURCES.map((source) => (
            <div
              key={source.name}
              className="h-16 bg-white dark:bg-zinc-900 rounded-lg flex items-center justify-center p-4"
            >
              <img
                src={source.logo}
                alt={source.alt}
                className="max-h-8 w-auto object-contain dark:invert"
              />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export function Feed() {
  return (
    <ErrorBoundary>
      <div className="space-y-4">
        <FeedHeader />
        <Card>
          <CardContent>
            <FeedTableContainer />
          </CardContent>
        </Card>
      </div>
    </ErrorBoundary>
  );
}

// Rename this function
function FeedTableHeader({ 
  feedData, 
  isRefreshing, 
  handleRefresh 
}: { 
  feedData: FeedEntry[]; 
  isRefreshing: boolean; 
  handleRefresh: () => void;
}) {
  return (
    <div className="flex items-center justify-between pt-4">
      <div className="space-y-1">
        <CardTitle>News Feed</CardTitle>
        <p className="text-sm text-muted-foreground">
          Showing updates from the last 24 hours • {feedData?.length || 0} items
        </p>
      </div>
      <Button 
        variant="outline" 
        size="sm" 
        onClick={handleRefresh}
        disabled={isRefreshing}
        className="ml-4"
      >
        <RefreshCw className={`mr-2 h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
        Refresh
      </Button>
    </div>
  );
}

function FeedFilters({ 
  selectedSentiment, 
  selectedSource, 
  searchInput, 
  onSentimentChange, 
  onSourceChange, 
  onSearchChange 
}: { 
  selectedSentiment: string;
  selectedSource: string;
  searchInput: string;
  onSentimentChange: (value: string) => void;
  onSourceChange: (value: string) => void;
  onSearchChange: (value: string) => void;
}) {
  return (
    <div className="flex flex-col sm:flex-row gap-4 pt-4">
      <Select 
        value={selectedSentiment}
        onValueChange={onSentimentChange}
      >
        <SelectTrigger className="w-full sm:w-[200px]">
          <SelectValue placeholder="Select sentiment" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Sentiment</SelectLabel>
            {SENTIMENT_OPTIONS.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select 
        value={selectedSource}
        onValueChange={onSourceChange}
      >
        <SelectTrigger className="w-full sm:w-[200px]">
          <SelectValue placeholder="Select source" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Source</SelectLabel>
            {SOURCE_CATEGORIES.map((category) => (
              <SelectItem key={category} value={category}>
                {category}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <div className="relative flex-1">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search content, title, or source..."
          value={searchInput}
          onChange={(e) => onSearchChange(e.target.value)}
          className="pl-8"
        />
      </div>
    </div>
  );
}

// This is the component that will be suspended
function FeedTableContainer() {
  // Move all the data fetching and state here
  const [selectedSource, setSelectedSource] = useState<string>('All Sources');
  const [selectedSentiment, setSelectedSentiment] = useState<SentimentType>('all');
  const [searchInput, setSearchInput] = useState('');
  const searchQuery = useDebounce(searchInput, 300);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItem] = useState<FeedEntry | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setHours(startDate.getHours() - 24);

  const { data: feedData, error, refetch, isLoading } = useFeed(
    startDate,
    endDate,
    selectedSource === 'All Sources' ? undefined : selectedSource,
    selectedSentiment === 'all' ? undefined : selectedSentiment,
    1000
  );

  // Log the feed data and sentiment for debugging
  useEffect(() => {
    console.log('Feed Data:', feedData);
    console.log('Selected Sentiment:', selectedSentiment);
  }, [feedData, selectedSentiment]);

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await queryClient.invalidateQueries({ queryKey: ['feed'] });
      await refetch();
      toast.success('Feed refreshed successfully');
    } catch (error) {
      toast.error('Failed to refresh feed');
    } finally {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 300);
    }
  }, [queryClient, refetch]);

  const handleSentimentChange = (value: string) => {
    console.log('Changing sentiment to:', value);
    setSelectedSentiment(value as SentimentType);
  };

  const handleRowClick = (item: FeedEntry) => {
    console.log('Feed handleRowClick called with:', item);
    setSelectedItem(item);
    setDialogOpen(true);
  };

  // Add type safety and array checking
  const safeFeedData = Array.isArray(feedData) ? feedData : [];
  
  const filteredData = useMemo(() => {
    return safeFeedData.filter(item => {
      // Apply sentiment filter
      if (selectedSentiment !== 'all' && item.Sentiment !== selectedSentiment) {
        return false;
      }

      // Apply source filter
      if (selectedSource !== 'All Sources') {
        const itemSource = getSourceCategory(item);
        if (itemSource !== selectedSource) {
          return false;
        }
      }

      // Apply search filter
      if (searchQuery?.trim()) {
        const searchLower = searchQuery.toLowerCase().trim();
        const titleMatch = item.Title?.toLowerCase().includes(searchLower);
        const contentMatch = item.Content?.toLowerCase().includes(searchLower);
        const outletMatch = (item.Outlet || item.From)?.toLowerCase().includes(searchLower);
        return titleMatch || contentMatch || outletMatch;
      }

      return true;
    });
  }, [safeFeedData, selectedSentiment, selectedSource, searchQuery]);

  // Add debugging
  useEffect(() => {
    if (selectedSource === 'Social Media') {
      console.log('Filtering for Social Media content...');
      feedData?.forEach(item => {
        console.log('Item From:', item.From);
        console.log('Category:', getSourceCategory(item));
      });
    }
  }, [selectedSource, feedData]);

  if (error) {
    return <ErrorAlert error={error} onRetry={handleRefresh} />;
  }

  return (
    <>
      <FeedTableHeader 
        feedData={filteredData}
        isRefreshing={isRefreshing}
        handleRefresh={handleRefresh}
      />
      <FeedFilters
        selectedSentiment={selectedSentiment}
        selectedSource={selectedSource}
        searchInput={searchInput}
        onSentimentChange={handleSentimentChange}
        onSourceChange={setSelectedSource}
        onSearchChange={setSearchInput}
      />
      {isLoading ? (
        <FeedTableSkeleton />
      ) : (
        <FeedTable 
          data={filteredData} 
          onRowClick={handleRowClick}
        />
      )}
      <FeedDialog 
        open={dialogOpen} 
        onOpenChange={setDialogOpen}
        selectedItem={selectedItem}
      />
    </>
  );
}

// Separate dialog into its own component
function FeedDialog({ 
  open, 
  onOpenChange, 
  selectedItem 
}: { 
  open: boolean; 
  onOpenChange: (open: boolean) => void;
  selectedItem: FeedEntry | null;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{selectedItem?.Title}</DialogTitle>
          <DialogDescription>
            {selectedItem?.Outlet} • {new Date(selectedItem?.Date || '').toLocaleDateString()}
          </DialogDescription>
        </DialogHeader>
        
        {selectedItem && (
          <div className="space-y-4">
            <div className="prose dark:prose-invert max-w-none">
              <p className="whitespace-pre-wrap">{selectedItem.Content}</p>
            </div>
            
            <div className="flex justify-between items-center text-sm text-muted-foreground">
              <div>Sentiment: {selectedItem.Sentiment}</div>
              <div>Source: {getSourceCategory(selectedItem)}</div>
            </div>

            <Button 
              className="w-full"
              onClick={() => window.open(selectedItem.URL, '_blank')}
            >
              Go to source
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

// Add a helper function to create an error alert
function ErrorAlert({ error, onRetry }: { error: Error | unknown; onRetry: () => void }) {
  return (
    <Alert variant="destructive">
      <AlertDescription className="flex items-center justify-between">
        <span>Failed to load feed data: {error instanceof Error ? error.message : 'Unknown error'}</span>
        <Button 
          variant="outline" 
          size="sm" 
          onClick={onRetry}
          className="ml-4"
        >
          <RefreshCw className="mr-2 h-4 w-4" />
          Retry
        </Button>
      </AlertDescription>
    </Alert>
  );
}

// Update the FeedTableSkeleton component
function FeedTableSkeleton() {
  return (
    <div className="space-y-2 mt-4">
      {Array.from({ length: 10 }).map((_, i) => (
        <div
          key={i}
          className={cn(
            "flex items-center p-4 rounded-lg h-[72px]",
            "bg-background border border-border"
          )}
        >
          {/* Sentiment indicator */}
          <div className="w-2 h-2 rounded-full bg-muted mr-4" />
          
          {/* Main content */}
          <div className="flex-1 min-w-0">
            <div className="space-y-2">
              {/* Title */}
              <div className="h-4 overflow-hidden">
                <Skeleton className="h-full w-[40%]" />
              </div>
              {/* Content preview */}
              <div className="h-3 overflow-hidden">
                <Skeleton className="h-full w-[70%]" />
              </div>
            </div>
          </div>
          
          {/* Source and date */}
          <div className="ml-4 w-[120px]">
            <div className="space-y-2">
              <div className="h-3 overflow-hidden">
                <Skeleton className="h-full w-full" />
              </div>
              <div className="h-3 overflow-hidden">
                <Skeleton className="h-full w-[60%]" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}