import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { toast } from 'sonner';

interface User {
  id: string;
  email: string;
  name: string;
  avatar?: string;
  role: 'user' | 'admin';
  plan: 'basic' | 'pro';
}

interface AuthState {
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signOut: () => void;
  updateProfile: (data: Partial<User>) => Promise<void>;
}

// In-memory user database simulation with default test user
const userDb = new Map<string, { password: string; user: User }>();

// Add default test user
const defaultUser: User = {
  id: '1',
  email: 'test@example.com',
  name: 'Test User',
  role: 'user',
  plan: 'pro',
  avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=32&h=32&fit=crop&crop=face'
};

// Initialize with default user
userDb.set('test@example.com', {
  password: 'test123',
  user: defaultUser
});

export const useAuth = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isLoading: false,
      isAuthenticated: false,

      signIn: async (email: string, password: string) => {
        try {
          set({ isLoading: true });
          
          // Simulate API call delay
          await new Promise(resolve => setTimeout(resolve, 500));
          
          const userRecord = userDb.get(email);
          if (!userRecord || userRecord.password !== password) {
            throw new Error('Invalid email or password');
          }
          
          set({ user: userRecord.user, isAuthenticated: true });
          toast.success('Welcome back!');
        } catch (error) {
          toast.error(error instanceof Error ? error.message : 'Failed to sign in');
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      signUp: async (email: string, password: string, name: string) => {
        try {
          set({ isLoading: true });
          
          // Simulate API call delay
          await new Promise(resolve => setTimeout(resolve, 500));
          
          if (userDb.has(email)) {
            throw new Error('Email already exists');
          }
          
          const user: User = {
            id: Date.now().toString(),
            email,
            name,
            role: 'user',
            plan: 'basic',
          };
          
          // Store in our mock database
          userDb.set(email, { password, user });
          
          set({ user, isAuthenticated: true });
          toast.success('Account created successfully!');
        } catch (error) {
          toast.error(error instanceof Error ? error.message : 'Failed to create account');
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      signOut: () => {
        set({ user: null, isAuthenticated: false });
        toast.success('Signed out successfully');
      },

      updateProfile: async (data: Partial<User>) => {
        try {
          set({ isLoading: true });
          
          const currentUser = useAuth.getState().user;
          if (!currentUser) throw new Error('Not authenticated');
          
          // Simulate API call delay
          await new Promise(resolve => setTimeout(resolve, 500));
          
          const updatedUser = { ...currentUser, ...data };
          
          // Update in mock database
          const userRecord = userDb.get(currentUser.email);
          if (userRecord) {
            userDb.set(currentUser.email, {
              ...userRecord,
              user: updatedUser
            });
          }
          
          set({ user: updatedUser });
          toast.success('Profile updated successfully');
        } catch (error) {
          toast.error(error instanceof Error ? error.message : 'Failed to update profile');
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },
    }),
    {
      name: 'auth-storage',
      skipHydration: false,
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated
      }),
    }
  )
);