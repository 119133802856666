import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DateRange } from 'react-day-picker';
import { subDays } from 'date-fns';

interface DateRangeState {
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
}

const getDefaultDateRange = (): DateRange => {
  const now = new Date();
  now.setHours(23, 59, 59, 999);  // Set to end of current day
  
  const from = subDays(now, 6);
  from.setHours(0, 0, 0, 0);  // Set to start of day
  
  return {
    from,
    to: now
  };
};

const defaultDateRange = getDefaultDateRange();

export const useDateRange = create<DateRangeState>()(
  persist(
    (set) => ({
      dateRange: defaultDateRange,
      setDateRange: (range) => {
        const to = new Date(range.to || new Date());
        to.setHours(23, 59, 59, 999);
        
        const from = new Date(range.from || subDays(to, 6));
        from.setHours(0, 0, 0, 0);
        
        set({ dateRange: { from, to } });
      },
    }),
    {
      name: 'date-range-storage',
      onRehydrateStorage: () => {
        // Ensure we always have a valid date range and today is the end date
        return (state) => {
          const today = new Date();
          today.setHours(23, 59, 59, 999);
          
          if (!state?.dateRange?.from || !state?.dateRange?.to || 
              state.dateRange.to.getDate() !== today.getDate()) {
            state?.setDateRange({
              from: state?.dateRange?.from || subDays(today, 6),
              to: today
            });
          }
        };
      },
    }
  )
);