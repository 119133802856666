import { memo, useState, useRef, useMemo, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import type { FeedEntry } from '@/lib/api/feed';
import { cn } from '@/lib/utils';
import { useVirtualizer } from '@tanstack/react-virtual';

interface FeedTableProps {
  data: FeedEntry[];
  onRowClick: (item: FeedEntry) => void;
}

const itemsPerPage = 50;

export const FeedTable = memo(function FeedTable({ 
  data, 
  onRowClick 
}: FeedTableProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);

  console.log('Total incoming data:', data.length);
  
  const last24Hours = useMemo(() => {
    const now = new Date();
    const twentyFourHoursAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000));
    const filtered = data.filter(item => new Date(item.Date) > twentyFourHoursAgo);
    console.log('Filtered 24h data:', filtered.length);
    return filtered;
  }, [data]);

  const totalPages = Math.ceil(last24Hours.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = last24Hours.slice(startIndex, endIndex);

  console.log({
    totalEntries: last24Hours.length,
    totalPages,
    currentPage,
    entriesOnCurrentPage: currentPageData.length
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const rowVirtualizer = useVirtualizer({
    count: currentPageData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 80,
    overscan: 10,
    measureElement: (element) => {
      return element.getBoundingClientRect().height;
    },
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom = virtualRows.length > 0 ? 
    rowVirtualizer.getTotalSize() - (virtualRows[virtualRows.length - 1]?.end || 0) : 0;

  const handleRowClick = (item: FeedEntry) => {
    console.log('Row clicked:', item);
    onRowClick(item);
  };

  return (
    <div className="flex flex-col border rounded-md" style={{ height: 'calc(100vh - 300px)' }}>
      <div className="flex-1 overflow-hidden">
        <div ref={parentRef} className="h-full overflow-auto">
          <table className="w-full">
            <thead className="sticky top-0 bg-background z-50 border-b">
              <tr>
                <th className="p-4 text-left font-medium text-muted-foreground w-[400px]">Content</th>
                <th className="p-4 text-left font-medium text-muted-foreground w-[25%]">Title</th>
                <th className="p-4 text-left font-medium text-muted-foreground w-[10%]">Sentiment</th>
                <th className="p-4 text-left font-medium text-muted-foreground w-[12.5%]">From</th>
                <th className="p-4 text-left font-medium text-muted-foreground w-[12.5%]">Date</th>
              </tr>
            </thead>
            <tbody>
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} colSpan={5} />
                </tr>
              )}
              {virtualRows.map((virtualRow) => {
                const item = currentPageData[virtualRow.index];
                return (
                  <tr
                    key={`${item.Date}-${virtualRow.index}`}
                    data-index={virtualRow.index}
                    ref={rowVirtualizer.measureElement}
                    onClick={() => handleRowClick(item)}
                    className="cursor-pointer hover:bg-muted/50 border-b transition-colors"
                  >
                    <td className="p-4 align-top">
                      <span className="block truncate whitespace-normal break-words max-w-[400px]">
                        {item.Content}
                      </span>
                    </td>
                    <td className="p-4 align-top">{item.Title}</td>
                    <td className="p-4 align-top">
                      <span className={cn(
                        "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold",
                        item.Sentiment === 'Positive' && "bg-green-100 text-green-800",
                        item.Sentiment === 'Negative' && "bg-red-100 text-red-800",
                        item.Sentiment === 'Neutral' && "bg-blue-100 text-blue-800"
                      )}>
                        {item.Sentiment}
                      </span>
                    </td>
                    <td className="p-4 align-top">{item.Outlet}</td>
                    <td className="p-4 align-top">{item.Date}</td>
                  </tr>
                );
              })}
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} colSpan={5} />
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="shrink-0 flex items-center justify-between px-4 py-2 border-t bg-background">
        <div className="text-sm text-muted-foreground">
          {`Showing ${startIndex + 1}-${Math.min(endIndex, last24Hours.length)} of ${last24Hours.length} posts`}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4 mr-1" />
            Previous
          </Button>
          <span className="text-sm text-muted-foreground px-2">
            Page {currentPage} of {Math.max(1, totalPages)}
          </span>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
            disabled={currentPage === totalPages || totalPages === 0}
          >
            Next
            <ChevronRight className="h-4 w-4 ml-1" />
          </Button>
        </div>
      </div>
    </div>
  );
});