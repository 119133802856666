import { cn } from "@/lib/utils"
import { Skeleton } from "./skeleton"

interface SkeletonItemProps {
  className?: string
  containerClassName?: string
}

export function SkeletonItem({ className, containerClassName }: SkeletonItemProps) {
  return (
    <div className={cn("h-4 overflow-hidden", containerClassName)}>
      <Skeleton className={cn("h-full", className)} />
    </div>
  )
} 